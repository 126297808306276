export const banks = [
  {
    value: "bci",
    name: "Banco BCI",
    label: "Banco BCI",
    onBoarding: true,
  },
  {
    value: "bankBICE",
    name: "Banco BICE",
    label: "Banco BICE",
    onBoarding: true,
  },
  {
    value: "bankChile2",
    name: "Banco de Chile",
    label: "Banco de Chile",
    onBoarding: true,
  },
  {
    value: "bankEstado",
    name: "Banco Estado",
    label: "Banco Estado",
    onBoarding: true,
  },
  {
    value: "bankItau",
    name: "Banco Itau",
    label: "Banco Itau",
    onBoarding: true,
  },
  {
    value: "santander",
    name: "Banco Santander",
    label: "Banco Santander",
    onBoarding: true,
  },
  {
    value: "security",
    name: "Banco Security",
    label: "Banco Security",
    onBoarding: true,
  },
  {
    value: "falabella",
    name: "Banco Falabella",
    label: "Banco Falabella",
    onBoarding: false,
  },
  {
    value: "scotiabank",
    name: "Banco Scotiabank",
    label: "Banco Scotiabank",
    onBoarding: true,
  },
  {
    value: "bankInternational",
    name: "Banco Internacional",
    label: "Banco Internacional",
    onBoarding: true,
  },
  {
    value: "ripley",
    name: "Banco Ripley",
    label: "Banco Ripley",
    onBoarding: false,
  },
  {
    value: "corpbanca",
    name: "Banco CORPBANCA",
    label: "Banco CORPBANCA",
    onBoarding: false,
  },
  {
    value: "consorcio",
    name: "Banco Consorcio",
    label: "Banco Consorcio",
    onBoarding: true,
  },
  {
    value: "coopeuch",
    name: "Banco Coopeuch",
    label: "Banco Coopeuch",
    onBoarding: false,
  },
  {
    value: "jpmorgan",
    name: "Banco JP Morgan",
    label: "Banco JP Morgan",
    onBoarding: false,
  },
  {
    value: "tenpo",
    name: "Tenpo",
    label: "Tenpo",
    onBoarding: false,
  },
  {
    value: 'mercadoPago',
    name: 'Mercado Pago',
    label: 'Mercado Pago',
    onBoarding: false,
  },
  {
    value: 'globalCard',
    name: 'Global Card',
    label: 'Global Card',
    onBoarding: false,
  },
  {
    value: 'cajaLosAndes',
    name: 'TAPP Caja Los Andes',
    label: 'TAPP Caja Los Andes',
    onBoarding: false,
  },
  {
    value: 'losHeroes',
    name: 'Prepago Los Heroes',
    label: 'Prepago Los Heroes',
    onBoarding: false,
  },
];

export const banksFormat = {
  bankBBVA: "Banco BBVA",
  bci: "Banco BCI",
  bankBICE: "Banco BICE",
  bankBice: "Banco BICE",
  bankChile: "Banco Chile",
  bankChile2: "Banco de Chile",
  bankEstado: "Banco Estado",
  bankItau: "Banco Itau",
  santander: "Banco Santander",
  security: "Banco Security",
  falabella: "Banco Falabella",
  scotiabank: "Banco Scotiabank",
  bankInternational: "Banco Internacional",
  international: "Banco Internacional",
  ripley: "Banco Ripley",
  corpbanca: "Banco CORPBANCA",
  consorcio: "Banco Consorcio",
  coopeuch: "Banco Coopeuch",
};

export const formatBanks = {
  bankBBVA: "BBVA",
  bci: "BCI",
  bankBICE: "BICE",
  bankBice: "BICE",
  bankChile: "Chile",
  bankChile2: "Chile",
  bankEstado: "Estado",
  bankItau: "Itau",
  santander: "Santander",
  security: "Security",
  falabella: "Falabella",
  scotiabank: "Scotiabank",
  bankInternational: "Internacional",
  internacional: "Internacional",
  ripley: "Ripley",
  corpbanca: "CORPBANCA",
  consorcio: "Consorcio",
  coopeuch: "Coopeuch",
};

export const Bank = {
  bankBBVA: "bankBBVA",
  bci: "bci",
  bankBICE: "bankBICE",
  bankBice: "bankBice",
  bankChile: "bankChile",
  bankChile2: "bankChile2",
  bankEstado: "bankEstado",
  bankItau: "bankItau",
  santander: "santander",
  security: "security",
  falabella: "falabella",
  scotiabank: "scotiabank",
  bankInternational: "bankInternational",
  internacional: "internacional",
  ripley: "ripley",
  corpbanca: "corpbanca",
  consorcio: "consorcio",
  coopeuch: "coopeuch",
}

export const paymentDays = [
  { key: 0, text: '0 días', value: 0 },
  { key: 15, text: '15 días', value: 15 },
  { key: 30, text: '30 días', value: 30 },
  { key: 45, text: '45 días', value: 45 },
  { key: 60, text: '60 días', value: 60 },
  { key: 90, text: '90 días', value: 90 },
]

export const bankAccountTypes = [
  { key: 'corriente', text: 'Corriente', value: 'corriente' },
  { key: 'vista', text: 'Vista', value: 'vista' },
  { key: 'ahorro', text: 'Ahorro', value: 'ahorro' },
  { key: 'chequera', text: 'Chequera Electrónica', value: 'chequera' },
  { key: 'pyme', text: 'Cuenta PYME', value: 'pyme' },
]