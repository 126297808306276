import { types } from "../types/types";

const initialState = {
  fcDetail: {},
  delayedPoints: {},
  settledPoints: {},
  regularPoints: {},
  displayDetail: {},
  areaName: {},
  fcData: {},
};

export const fcDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getFcData:
      return {
        ...state,
        fcData: { ...action.payload },
      };
    case types.getFcDetail:
      return {
        ...state,
        fcDetail: { ...action.payload },
      };
    case types.getDelayedPoints:
      return {
        ...state,
        delayedPoints: { ...action.payload },
      };
    case types.getSettledPoints:
      return {
        ...state,
        settledPoints: { ...action.payload },
      };
    case types.getRegularPoints:
      return {
        ...state,
        regularPoints: { ...action.payload },
      };
    case types.getDisplayDetail:
      return {
        ...state,
        displayDetail: { ...action.payload },
      };
    case types.getAreaName:
      return {
        ...state,
        areaName: { ...action.payload },
      };
    case types.authLogout:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
