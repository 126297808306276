import { types } from "../types/types";

const initialState = {
  panelAgenda: [],
  getUpdateAgendaNeeded: { value: false }
};

export const agendaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getPanelAgenda:
      return {
        ...state,
        panelAgenda: [...action.payload],
      };
    case types.authLogout:
      return {
        ...initialState,
      };
    case types.getUpdateAgendaNeeded:
      return { ...state, getUpdateAgendaNeeded: action.payload }
    default:
      return state;
  }
};
