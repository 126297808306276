import { types } from "../types/types";

import {
  flujoCajaTablaSemanal,
  flujoCajaTablaMensual,
  financiamientoCard,
} from "../../../src/data";

export const getFlujoCajaMensual = (values) => ({
  type: types.getFlujoCajaMensual,
  payload: values,
});
export const getFlujoCajaPasado = (values) => ({
  type: types.getFlujoCajaPasado,
  payload: values,
});
export const getFlujoCajaTablaSemanal = (values) => ({
  type: types.getFlujoCajaTablaSemanal,
  payload: values,
});

export const getFinanciamientoCard = (values) => ({
  type: types.getFinanciamientoCard,
  payload: values,
});
export const getFlujoCajaCurrency = (values) => ({
  type: types.getFlujoCajaCurrency,
  payload: values,
});

export const importFlujoDetalle = (data) => {
  return async (dispatch) => {
    try {
      dispatch(getFlujoCajaMensual(data?.Cashflow));
    } catch (error) {
      console.error(error);
    }
  };
};
export const importFlujoPasado = (data) => {
  return async (dispatch) => {
    try {
      dispatch(getFlujoCajaPasado(data?.PastCashflow));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importFlujoResumen = (checked) => {
  return async (dispatch) => {
    try {
      if (checked) dispatch(getFlujoCajaTablaSemanal(flujoCajaTablaSemanal));
      else dispatch(getFlujoCajaTablaSemanal(flujoCajaTablaMensual));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importFinanciamientoCard = () => {
  return async (dispatch) => {
    try {
      dispatch(getFinanciamientoCard(financiamientoCard));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getUpdateNeeded = (values) => ({
  type: types.getUpdateNeeded,
  payload: values,
});

export const getUpdateCashflowNeeded = (values) => ({
  type: types.getUpdateCashflowNeeded,
  payload: values,
});

export const getUpdateCashflowStructureNeeded = (values) => ({
  type: types.updateCashflowStructure,
  payload: values,
});

export const getUpdateBalanceNeeded = (values) => ({
  type: types.getUpdateBalanceNeeded,
  payload: values,
});

export const importUpdateNeeded = (values) => {
  return async (dispatch) => {
    try {
      dispatch(getUpdateNeeded(values));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importUpdateCashflowNeeded = (values) => {
  return async (dispatch) => {
    try {
      dispatch(getUpdateCashflowNeeded(values));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importUpdateCashflowStructureNeeded = (values) => {
  return async (dispatch) => {
    try {
      dispatch(getUpdateCashflowStructureNeeded(values));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importUpdateBalanceNeeded = (values) => {
  return async (dispatch) => {
    try {
      dispatch(getUpdateBalanceNeeded(values));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importFlujoCajaCurrency = (values) => {
  return async (dispatch) => {
    try {
      dispatch(getFlujoCajaCurrency(values));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getCashFlowCategories = (values) => ({
  type: types.cashFlowCategories,
  payload: values,
});

export const cashFlowCategories = (values) => {
  return async (dispatch) => {
    try {
      dispatch(getCashFlowCategories(values));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getCashFlowAreas = (values) => ({
  type: types.cashFlowAreas,
  payload: values,
});

export const cashFlowAreas = (values) => {
  return async (dispatch) => {
    try {
      dispatch(getCashFlowAreas(values));
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateCashFlow = (values) => ({
  type: types.updateCashFlow,
  payload: values,
});

export const importUpdateCashFlow = (values) => {
  return async (dispatch) => {
    try {
      dispatch(updateCashFlow(values));
    } catch (error) {
      console.error(error);
    }
  };
};
