import { types } from "../types/types";

const initialState = {
  barCreditos: {},
  barCreditosLine: {},
  creditosTable: {},
  creditosVigentes: [],
  creditosResumen: [],
  creditsDocuments: [],
  allCredits: [],
  creditsEvolution: [],
};

export const creditosReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getBarCreditos:
      return {
        ...state,
        barCreditos: {
          ...action.payload,
        },
      };
    case types.getBarCreditosLine:
      return {
        ...state,
        barCreditosLine: {
          ...action.payload,
        },
      };
    case types.getCreditosTable:
      return {
        ...state,
        creditosTable: {
          ...action.payload,
        },
      };
    case types.getCreditosVigentes:
      return {
        ...state,
        creditosVigentes: [...action.payload],
      };
    case types.getCreditosResumen:
      return {
        ...state,
        creditosResumen: [...action.payload],
      };
    case types.getCreditsDocuments:
      return {
        ...state,
        creditsDocuments: [...action.payload],
      };
    case types.getCredits:
      return {
        ...state,
        allCredits: [...action.payload],
      };
    case types.getCreditsEvolution:
      return {
        ...state,
        creditsEvolution: [...action.payload],
      };
    case types.authLogout:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
