import { types } from "../types/types";

const initialState = {
  selectedData: {},
  updateCobranza: false,
};

export const cobranzaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_DATA':
      return {
        ...state,
        selectedData: action.payload,
      };
    case types.updateCobranza:
      return {
        ...state,
        updateCobranza: action.payload
      }
    default:
      return state;
  }
};