import { types } from "../types/types";

const initialState = {
  paginatedUsers: [],
  totalUsers: 0,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getPaginatedUsers:
      return {
        ...state,
        paginatedUsers: [
          ...(action.payload?.paginatedUsersByCompany.docs || []),
        ],
        totalUsers: action.payload?.paginatedUsersByCompany?.totalDocs || 0,
      };
    case types.authLogout:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
