import { useMutation } from "urql";

const UPDATEPOINT = `mutation UpdatePoint($id: String!, $companyId: String!, $input: PointInput!) {
    updatePoint(id: $id, companyId: $companyId, input: $input) {
      id
    }
  }`;
  

export const PostUpdatePoint = () => {
  const [updatePoint, postUpdatePoint] = useMutation(UPDATEPOINT);
  return postUpdatePoint;
};

const UPDATEMANUALDOCUMENT = `mutation UPDATE_MANUAL_DOCUMENT($id: String!, $input: ManualDocumentInput!) {
	updateManualDocument(id: $id, input: $input) {
		id
	}
}`;

export const UpdateManualDocument = () => {
  const [result, updateManualDocument] = useMutation(UPDATEMANUALDOCUMENT);
  
  const { data, fetching, error } = result;
  return { data, fetching, error, updateManualDocument };
};

const UPDATEINITIALBALANCE = `mutation UPDATE_INITIAL_BALANCE($id: String!, $input: InitialBalanceDocumentInput!) {
  updateInitialBalanceDocument(id: $id, input: $input) {
    id
  }
}`;

export const UpdateInitialBalance = () => {
  const [result, updateInitialBalance] = useMutation(UPDATEINITIALBALANCE);
  
  const { data, fetching, error } = result;
  return { data, fetching, error, updateInitialBalance };
};

const DELETEDOCUMENT = `mutation DELETE_DOCUMENT($id: String!) {
	deleteDocument(id: $id) {
		id
	}
}`;

export const DeleteDocument = () => {
  const [DeletePoint, deleteManualDocument] = useMutation(DELETEDOCUMENT);
  return deleteManualDocument;
};

const UPDATEMANUALDOCUMENT2 = `mutation SETTLE_POINT($companyId: String!, $pointId: String!) {
	settlePoint(companyId: $companyId, pointId: $pointId)
}`;

export const SettlePoint = () => {
  const [result, settlePoint] = useMutation(UPDATEMANUALDOCUMENT2);
  
  const { data, fetching, error } = result;
  return { data, fetching, error, settlePoint };
};