import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { importNewModal } from "../../redux/actions/newModal";
import { Button, Modal, Header, Icon } from "semantic-ui-react";
//import Button from "../general/js/formTypes/button";
import "./newModal.scss";
import "../../scss/_semanticForms.scss"

export default function ModalBox() {
  const dispatch = useDispatch();
  const { showModal } = useSelector((state) => state.newModal);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    dispatch(importNewModal({ show: false }));
  };

  const onConfirm = () => {
    setLoading(true)
    
    try {
      showModal.onConfirm()
    } catch (e) {
      console.error(e.message)
    }

    setLoading(false) 
  }

  return (
    <Modal
      closeIcon
      open={showModal?.show}
      trigger={<div></div>}
      onClose={closeModal}
      onOpen={showModal?.onConfirm}
      size={showModal?.size || 'small'}
      dimmer={{ style: { zIndex: 9999 , backgroundColor: 'rgba(0,0,0,.6)'  , backdropFilter: 'blur(3px)'} }} 
    >
      <Header as="h3" icon={ showModal?.icon || null } content={showModal?.title || ""} />
      <Modal.Content>
        <p>
          { showModal?.content && <Header as="h4">{ showModal.content }</Header> }
          { showModal?.subcontent ? showModal?.subcontent : "" }
        </p>
        <p className="mt-2">
        { showModal?.subsubcontent ? showModal?.subsubcontent : "" }
        </p>
        { showModal?.children && showModal.children }
      </Modal.Content>
      <Modal.Actions className={showModal.showDeleteButton ? 'modalForm__content__actionButtons' : ''} >
      { showModal.showDeleteButton &&
          <Button  color='grey' className="modalForm__content__deleteBtn" disabled={showModal?.deleteDisable} onClick={() => showModal.onDelete()}>
            <Icon name='trash alternate outline' /> { showModal?.onDeleteText || "Eliminar" }
          </Button>
      }
      { !showModal.hideCancelButton && <Button onClick={closeModal}>
          { showModal?.cancelButtonIcon && <Icon name={showModal.cancelButtonIcon} /> } { showModal?.onCancelText || "Cancelar" }
          </Button>
      }
      { !showModal.hideAcceptButton && <Button color='blue' loading={loading} onClick={onConfirm}>
          { showModal?.confirmButtonIcon && <Icon name={showModal.confirmButtonIcon} /> } { showModal?.onConfirmText || "Aceptar" } { showModal?.confirmButtonIconRight && <Icon name={showModal.confirmButtonIconRight} /> }
        </Button>
      }
      </Modal.Actions>
    </Modal>
  )
}
