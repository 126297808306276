import { types } from "../types/types";

const initialState = {
  dataProveedores: [],
  selectedDTEs: [],
  excludedProviders: [],
  balances: [],
  nominaSelected: [],
  selected: null,
  nominaFilter: {},
  nominaFiltered: [],
  updateProviders: false,
  updateNominas: false,
  inProcessNomina: [],
  processedNominas: [],
  getUpdateNominaInProcessNeeded: { value: false },
  getUpdateNominaProcessedNeeded: { value: false },
};

export const proveedoresReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getDataProveedores:
      return {
        ...state,
        dataProveedores: action?.payload || initialState.dataProveedores,
      };
    case types.getSelectedDTEs:
      return {
        ...state,
        selectedDTEs: action?.payload || initialState.selectedDTEs,
      };
    case types.getExcludedProviders:
      return {
        ...state,
        excludedProviders: action?.payload || initialState.excludedProviders,
      };
    case types.getBalances:
      return {
        ...state,
        balances: action?.payload || initialState.balances,
      };
    case types.getNominaVencida:
      return {
        ...state,
        nominaVencida: action?.payload || initialState.dataProveedores,
      };
    case types.getNominaSelected:
      return {
        ...state,
        nominaSelected: action?.payload || initialState.nominaSelected,
      };
    case types.getNominaState:
      return {
        ...state,
        selected: action?.payload || initialState.selected,
      };

    case types.getFilterNomina:
      return {
        ...state,
        nominaFilter: action?.payload || initialState.selected,
      };

    case types.getNominaFiltered:
      return {
        ...state,
        nominaFiltered: action?.payload || initialState.selected,
      };
    case types.authLogout:
      return {
        ...initialState,
      };
    case types.updateProviders:
      return {
        ...initialState,
        updateProviders: action?.payload || initialState.updateProviders
      };
    case types.updateNominasInProcess:
      return {
        ...initialState,
        updateNominas: action?.payload || initialState.updateNominas
      };
    case types.getInProcessNomina:
        return {
          ...state,
          inProcessNomina: action?.payload || initialState.inProcessNomina
        };
    case types.getProcessedNominas:
        return {
          ...state,
          processedNominas: action?.payload || initialState.processedNominas
        };
    case types.getUpdateNominaInProcessNeeded:
      return { ...state, getUpdateNominaInProcessNeeded: action.payload }
    case types.getUpdateNominaProcessedNeeded:
      return { ...state, getUpdateNominaProcessedNeeded: action.payload }
    default:
      return state;
  }
};
