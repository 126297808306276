import { types } from "../types/types";

export const newShowModal = (show) => ({
  type: types.getModalComponent,
  payload: show,
});

export const importNewModal = (show) => {
  return async (dispatch) => {
    try {
      dispatch(newShowModal(show));
    } catch (error) {
      console.error(error);
    }
  };
};
