import { types } from '../types/types'

const initialState = {
    lastBankUpdated: null
}

export const lastBankUpdatedReducer = ( state = initialState, action ) => {
    switch(action.type) {
        case types.lastBankUpdated:
            return {
                ...state,
                lastBankUpdated: action.payload
            }
        default:
            return state
    }
}