import { types } from "../types/types";

const initialState = {
  flujocajamensual: {},
  flujocajaPasado: {},
  flujoCajaTablaSemanal: {},
  financiamientoCard: {},
  updateNeeded: {},
  cashFlowCategories: {},
  cashFlowAreas: {},
  currency: 'CLP',
  updateCashflowData: false,
  updateCashflowNeeded: { value: false, reload: true },
  updateCashflowStructure: { value: false },
};

export const flujoCajaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getFlujoCajaMensual:
      return {
        ...state,
        flujocajamensual: {
          ...action.payload,
        },
      };
    case types.getFlujoCajaPasado:
      return {
        ...state,
        flujocajaPasado: {
          ...action.payload,
        },
      };
    case types.getUpdateNeeded:
      return {
        ...state,
        updateNeeded: { ...action.payload },
      };
    case types.getUpdateCashflowNeeded:
      return {
        ...state,
        updateCashflowNeeded: { ...action.payload },
      }; 
    case types.updateCashflowStructure:
      return {
        ...state,
        updateCashflowStructure: { ...action.payload },
      };  
    case types.getUpdateBalanceNeeded:
      return {
        ...state,
        updateBalanceNeeded: { ...action.payload },
      };        
    case types.getFlujoCajaTablaSemanal:
      return {
        ...state,
        flujoCajaTablaSemanal: {
          ...action.payload,
        },
      };
    case types.getFinanciamientoCard:
      return {
        ...state,
        financiamientoCard: {
          ...action.payload,
        },
      };
    case types.cashFlowCategories:
      return {
        ...state,
        cashFlowCategories: {
          ...action.payload,
        },
      };
    case types.cashFlowAreas:
      return {
        ...state,
        cashFlowAreas: {
          ...action.payload,
        },
      };
    case types.getFlujoCajaCurrency:
      return {
        ...state,
        currency: action.payload,
      };
    case types.updateCashFlow:
      return {
        ...state,
        updateCashflowData: action.payload,
      };   
    case types.authLogout:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
