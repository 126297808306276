import { useQuery, useMutation } from "urql";
import { makeOperation } from "@urql/core";
import { startLogout } from "../redux/actions/auth";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";

const LOG_IN = `
mutation login($email:String!,$pass:String!){
    login(input:{email:$email,password:$pass}){
      accessToken
    }
  }
`;

export const LogIn = () => {
  const [updateLogIn, logIn] = useMutation(LOG_IN);
  return logIn;
};

const SIGN_IN = `
mutation login($email:String!,$pass:String!){
    login(input:{email:$email,password:$pass}){
      accessToken
    }
  }
`;

export const SignIn = () => {
  const [result, reexecuteMutation] = useMutation(SIGN_IN);
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteMutation };
}

export const getAuth = async ({ authState }) => {
  if (!authState) {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("token");
    if (token && refreshToken) {
      return { token, refreshToken };
    }
    return null;
  }
  localStorage.setItem("logOut", true);
  return null;
};

export const addAuthToOperation = ({ authState, operation }) => {
  if (!authState || !authState.token) {
    return operation;
  }
  const { user, exp } = jwt_decode(authState?.token);
  if (exp < new Date()) {
  }
  const fetchOptions =
    typeof operation.context.fetchOptions === "function"
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};
  return makeOperation(operation.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        Authorization: "Bearer " + authState.token,
      },
    },
  });
};

export const didAuthError = ({ error }) => {
  return error.graphQLErrors.some((e) => e.extensions?.code === "FORBIDDEN");
};

export const willAuthError = ({ operation, authState }) => {
  if (!authState) {
    return !(
      operation.kind === "mutation" &&
      operation.query.definitions.some((definition) => {
        return (
          definition.kind === "OperationDefinition" &&
          definition.selectionSet.selections.some((node) => {
            return node.kind === "Field" && node.name.value === "login";
          })
        );
      })
    );
  } else {
    const { exp } = jwt_decode(authState?.token);
    const now = new Date().getTime() / 1000;
    if (exp < now) {
      return true;
    }
  }

  return false;
};

const REGISTER = `mutation REGISTER($input: UserInput!) {
  signUp(input: $input) {
    id
  }
}`;

export const Register = () => {
  const [result, executeMutation] = useMutation(REGISTER);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};
const CHECKEMAIL = `query CHECKEMAIL($email: String!){
  emailExist(email:$email)
}`;
export const CheckEmail = (email) => {
  const [result, executeQuery] = useQuery({
    query: CHECKEMAIL,
    variables: { email },
  });
  const { data, fetching, error } = result;
  return { data, fetching, error, executeQuery, result };
};
const CHANGE_PASSWORD = `mutation CHANGE_PASSWORD(
    $input: ChangePasswordFromResetInput!
  ) {
  changePasswordFromReset(input : $input)
}
`;

export const ChangePassword = () => {
  const [updateLogIn, changePassword] = useMutation(CHANGE_PASSWORD);
  return changePassword;
};

const IS_TOKEN_VALID = `query IS_TOKEN_VALID($token: String!) {
  isTokenValid(token: $token)
}
`;

export const IsTokenValid = (token) => {
  const [result, reexecuteQuery] = useQuery({
    query: IS_TOKEN_VALID,
    variables: { token },
  });
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteQuery };
};
