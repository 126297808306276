export const botList = [
  {
    value: "bci",
    name: "Banco BCI",
  },
  {
    value: "bankBICE",
    name: "Banco BICE",
  },
  {
    value: "bankChile",
    name: "Banco Chile",
  },
  {
    value: "bankChile2",
    name: "Banco de Chile",
  },
  {
    value: "bankEstado",
    name: "Banco Estado",
  },
  {
    value: "bankItau",
    name: "Banco Itau",
  },
  {
    value: "santander",
    name: "Banco Santander",
  },
  {
    value: "security",
    name: "Banco Security",
  },
  {
    value: "falabella",
    name: "Banco Falabella",
  },
  {
    value: "scotiabank",
    name: "Banco Scotiabank",
  },
  {
    value: "bankInternational",
    name: "Banco Internacional",
  },
  {
    value: "ripley",
    name: "Banco Ripley",
  },
  {
    value: "corpbanca",
    name: "Banco CORPBANCA",
  },
  {
    value: "consorcio",
    name: "Banco Consorcio",
  },
  {
    value: "coopeuch",
    name: "Banco Coopeuch",
  },
  {
    value: "F29/Status",
    name: "F29",
  },
  {
    value: "PREVIRED/imposiciones",
    name: "Imposiciones",
  },
  {
    value: "clayApiToken",
    name: "Documentos Tributarios",
  },
  {
    value: "bsaleApiToken",
    name: "Bsale",
  },
  {
    value: "transbank",
    name: "Abonos de Transbank",
  },
  {
    value: "sii",
    name: "Documentos Tributarios",
  },
  {
    value: "previred",
    name: "Imposiciones",
  },
];

export const botIndex = botList.reduce((acc, el) => {
  acc[el.value] = el.name
  return acc
} , {})