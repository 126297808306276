import React from 'react'
import { Dropdown } from 'semantic-ui-react'

export const currencyOptions = [
  { key: 'CLP', value: 'CLP', flag: 'cl', text: 'Pesos' },
  { key: 'USD', value: 'USD', flag: 'us', text: 'Dólar' },
  { key: 'EUR', value: 'EUR', flag: 'eu', text: 'Euro' },
  //{ key: 'GBP', value: 'GBP', flag: 'gb', text: 'Libra' },
  //{ key: 'MXN', value: 'MXN', flag: 'mx', text: 'Pesos' },
]

export const currencyOptionsWithSpecials = [
  { key: 'CLP', value: 'CLP', flag: 'cl', text: 'Pesos', prefix: '$', separation: '.' },
  { key: 'UF', value: 'UF', flag: 'cl', text: 'UF', prefix: 'UF', separation: ',' },
  { key: 'USD', value: 'USD', flag: 'us', text: 'Dólar', prefix: 'US$', separation: '.' },
  { key: 'EUR', value: 'EUR', flag: 'eu', text: 'Euro', prefix: '€', separation: '.' },
  { key: 'UTM', value: 'UTM', flag: 'cl', text: 'UTM', prefix: 'UTM', separation: '.' },
  //{ key: 'GBP', value: 'GBP', flag: 'gb', text: 'Libra' },
  //{ key: 'MXN', value: 'MXN', flag: 'mx', text: 'Pesos' },
]

export const CurrenciesDropdown = ({
  style = {},
  onChange = () => { },
  onBlur = () => { },
  className = '',
  id = 'currency',
  name = 'currency',
  placeholder = 'Seleccione Moneda...',
  multiple = false,
  clearable = true,
  value = '',
}) => {
  return (
    <Dropdown
      selectOnBlur={false}
      className={className}
      multiple={multiple}
      style={{ ...style }}
      placeholder={placeholder}
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      options={currencyOptions}
      onBlur={onBlur}
      clearable={clearable}
      selection
      search
      button basic floating
      fluid
    />
  )
}
