import { types } from "../types/types";

const initialState = {
  alertState: {},
};

export const alertStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getAlertState:
      return {
        ...state,
        alertState: { ...action.payload, time: new Date(), id: Math.random() },
      };
    case types.authLogout:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
