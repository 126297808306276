import { types } from "../types/types";

const initialState = {
  userId: '',
  userRut: '',
  userEmail: '',
  userName: '',
  userLastName: '',
  companyId: '',
  companyRut: '',
  companyName: '',
  companyAlias: '',
};

export const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getOnboardingUserId:
      return {
        ...state,
        userId: action?.payload || initialState.userId,
      };
    case types.getOnboardingUserRut:
      return {
        ...state,
        userRut: action?.payload || initialState.userRut,
      };
    case types.getOnboardingUserEmail:
      return {
        ...state,
        userEmail: action?.payload || initialState.userEmail,
      };
    case types.getOnboardingUserName:
      return {
        ...state,
        userName: action?.payload || initialState.userName,
      };
    case types.getOnboardingUserLastName:
      return {
        ...state,
        userLastName: action?.payload || initialState.userLastName,
      };
    case types.getOnboardingCompanyId:
      return {
        ...state,
        companyId: action?.payload || initialState.companyId,
      };
      case types.getOnboardingCompanyRut:
      return {
        ...state,
        companyRut: action?.payload || initialState.companyRut,
      };
    case types.getOnboardingCompanyName:
      return {
        ...state,
        companyName: action?.payload || initialState.companyName,
      };
    case types.getOnboardingCompanyAlias:
      return {
        ...state,
        companyAlias: action?.payload || initialState.companyAlias,
      };
    default:
      return initialState;
  }
};