import { types } from "../types/types";

import {
  barDashboardLine,
  lineDashboard,
  notificacionesDashboard,
  panelesDashboard,
  cobranzaApi,
} from "../../../src/data";

export const getSidebarSize = (sidebarSize) => ({
  type: types.sideBarMinimized,
  payload: sidebarSize,
});

export const importSidebarSize = (sidebarSize) => {
  return async (dispatch) => {
    try {
      dispatch(getSidebarSize(sidebarSize));
    } catch (e) {
      console.error(e);
    }
  };
};

export const getBarDashboard = (values) => ({
  type: types.getBarDashboard,
  payload: values,
});

export const getBarDashboardLine = (values) => ({
  type: types.getBarDashboardLine,
  payload: values,
});

export const getLineDashboard = (values) => ({
  type: types.getLineDashboard,
  payload: values,
});

export const getNotificacionesDashboard = (values) => ({
  type: types.getNotificacionesDashboard,
  payload: values,
});
export const getCobranzaDashboard = (values) => ({
  type: types.getCobranzaDashboard,
  payload: values,
});

export const getPanelesDashboard = (values) => ({
  type: types.getPanelesDashboard,
  payload: values,
});

export const setBarDashboardLine = (values) => ({
  type: types.setBarDashboardLine,
  payload: values,
});

export const getCobranzaApi = (values) => ({
  type: types.getCobranzaApi,
  payload: values,
});

export const getSelectedClient = (values) => ({
  type: types.selectedClient,
  payload: values,
});

export const importSelectedClient = (company) => {
  return async (dispatch) => {
    try {
      dispatch(getSelectedClient(company));
    } catch (e) {
      console.error(e);
    }
  };
};

export const getSelectedFilter = (values) => ({
  type: types.selectedFilter,
  payload: values,
});

export const getConciliationRatio = (values) => ({
  type: types.conciliationRatio,
  payload: values,
});

export const getConciliationRatioUpdate = () => ({
  type: types.conciliationRatioUpdate,
});

export const getUpdatedDashboardNeeded = (values) => ({
  type: types.getUpdateDashboardNeeded,
  payload: values,
});

export const importSelectedFilter = (filter) => {
  return async (dispatch) => {
    try {
      dispatch(getSelectedFilter(filter));
    } catch (e) {
      console.error(e);
    }
  };
};

export const importUpdateDashboardNeeded = (values) => {
  return async (dispatch) => {
    try {
      dispatch(getUpdatedDashboardNeeded(values));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importDataDashboard = (Landing) => {
  return async (dispatch) => {
    try {
      dispatch(getBarDashboard([Landing?.last12MonthSales]));
      dispatch(getPanelesDashboard(panelesDashboard));
      dispatch(getBarDashboardLine([barDashboardLine]));
      dispatch(getLineDashboard(lineDashboard));
      dispatch(getNotificacionesDashboard(notificacionesDashboard));
      dispatch(getCobranzaDashboard([Landing?.paimentColectionTable]));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importLineDashboard = (Data) => {
  return async (dispatch) => {
    try {
      dispatch(getLineDashboard(Data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importDataCobranzas = () => {
  return async (dispatch) => {
    try {
      dispatch(getCobranzaApi(cobranzaApi));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importConcilieantionRatio = (data) => {
  return async (dispatch) => {
    try {
      dispatch(getConciliationRatio(data));
    } catch (error) {
      console.error(error);
    }
  };
};
export const updateConcilieantionRatio = () => {
  return async (dispatch) => {
    try {
      dispatch(getConciliationRatioUpdate());
    } catch (error) {
      console.error(error);
    }
  };
};
