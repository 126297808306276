import { types } from "../types/types";

export const getDocTributarios = (values) => ({
  type: types.getDocTributarios,
  payload: values,
});

export const getDocumentsFilter = (values) => ({
  type: types.getDocumentsFilter,
  payload: values,
});

export const getDocumentsTypes = (values) => ({
  type: types.documentTypes,
  payload: values,
});

export const getSelectedType = (values) => ({
  type: types.selectedType,
  payload: values,
});

export const getExcludeDocumentCodes = (values) => ({
  type: types.getExcludeDocumentCodes,
  payload: values,
});

export const importDataTributarios = (documents) => {
  return async (dispatch) => {
    try {
      dispatch(getDocTributarios(documents));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importDocumentsFilter = (state) => {
  return async (dispatch) => {
    try {
      dispatch(getDocumentsFilter(state));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importDocumentsTypes = (state) => {
  return async (dispatch) => {
    try {
      dispatch(getDocumentsTypes(state));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importSelectedType = (state) => {
  return async (dispatch) => {
    try {
      dispatch(getSelectedType(state));
    } catch (error) {
      console.error(error);
    }
  };
};

export const importExcludedDocumentCodes = (state) => {
  return async (dispatch) => {
    try {
      dispatch(getExcludeDocumentCodes(state));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getSelectedDocuments = (values) => ({
  type: types.getSelectedDocuments,
  payload: values,
});
export const setSelectedDocuments = (state) => {
  return async (dispatch) => {
    try {
      dispatch(getSelectedDocuments(state));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getUpdateDocuments = (values) => ({
  type: types.getUpdateDocuments,
  payload: values,
});
export const setUpdateDocuments = (state) => {
  return async (dispatch) => {
    try {
      dispatch(getUpdateDocuments(state));
    } catch (error) {
      console.error(error);
    }
  };
};

export const setUpdateDocumentsTypes = (state) => {
  return async (dispatch) => {
    try {
      dispatch(updateDocumentsType(state));
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateDocumentsType = (values) => ({
  type: types.updateDocumentTypesFilter,
  payload: values,
});

export const cleanSelectedDocuments = (values) => ({
  type: types.cleanSelectedDocuments,
  payload: values,
});

export const importCleanSelectedDocuments = (state) => {
  return async (dispatch) => {
    try {
      dispatch(cleanSelectedDocuments(state))
    } catch (error) {
      console.error(error)
    }
  }
} 