import moment from "moment";
import { types } from "../types/types";

const initialState = {
  eerrData: [
    {
      startDate: moment().format("YYYY-MM-01"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  ],
  detailEerr: { show: false },
  getUpdateEERRNeeded: { value: false }
};
export const eerrReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.eerr:
      return {
        ...state,
        eerrData: action.payload,
      };
    case types.detailEerr:
      return { ...state, detailEerr: action.payload };
    case types.getUpdateEERRNeeded:
      return { ...state, getUpdateEERRNeeded: action.payload }
    default:
      return state;
  }
};
