import { types } from "../types/types";

const initialState = {
  sidebarShow: "responsive",
  forms: [],
  dropdownCurrency: "USD",
  currency: {},
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.set:
      return {
        ...state,
        sidebarShow: action.payload,
      };
    case types.getForms:
      return {
        ...state,
        forms: action.payload,
      };
    case types.setCurrency:
      return {
        ...state,
        dropdownCurrency: action.payload,
      };
    case types.getCurrency:
      return {
        ...state,
        currency: { ...action.payload },
      };
    case types.authLogout:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
