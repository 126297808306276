import { types } from "../types/types";

const initialState = {
  enhanced: {},
};

export const enhancedIconsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getEnhancedIcons:
      return {
        ...state,
        enhanced: { ...action.payload },
      };
    default:
      return state;
  }
};
