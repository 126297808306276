import { types } from "../types/types";
import jwt_decode from "jwt-decode";
import { importNewModal } from "./newModal";
const login = (user) => ({
  type: types.authLogin,
  payload: user,
});

const updateCashflow = (cashflowCategories) => ({
  type : types.updateCashflowCollapsedCategories,
  payload: cashflowCategories
})

const firstTimeOnApp = (payload) => ({
  type : types.authFirstTime,
  payload
})

const showWelcomeModal = (payload) => ({
  type : types.authShowWelcomeModal,
  payload
})

export const UpdateCashflowCategories = (cashflow) => {
  return async (dispatch) => {
    dispatch(updateCashflow(cashflow))
  }
}

export const setFirstTimeOnApp = (payload) => {
  return async (dispatch) => {
    dispatch(firstTimeOnApp(payload))
  }
}

export const setShowWelcomeModal = (payload) => {
  return async (dispatch) => {
    dispatch(showWelcomeModal(payload))
  }
}

const updateNominasFilter = (filter) => ({
  type: types.updateNominasFilter,
  payload: filter
})

export const updateNominaFilterByUser = (filter) => {
  return async (dispatch) => {
    dispatch(updateNominasFilter(filter))
  }
}

export const startChecking = () => {
  return async (dispatch) => {
    let user = JSON.parse(localStorage.getItem("user"));

    if (!!user?.uid) {
      dispatch(importNewModal({ show: false }))
    }

    if (user) {
      dispatch(
        login({
          uid: user.uid,
          name: user.name,
          surname: user.surname,
          email: user.email,
        })
      );
    }
  };
};

export const startLogin = (token) => {
  const { user, exp } = jwt_decode(token);
  const lastLocation = null;
  const history = [];
  return async (dispatch) => {
    if (token) {
      window.heap.identify(user.id);
      window.heap.addUserProperties({
        uid: user.id,
        name: user.profile.firstName,
        surname: user.profile.lastName,
        email: user.emails[0].address,
      });
      const userData = {
        uid: user.id,
        name: user.profile.firstName,
        surname: user.profile.lastName,
        email: user.emails[0].address,
        roles: user.roles,
        sessionExp: exp,
        flowView: user.flowView || [{}],
        cashflowCollapsedCategories: user.cashflowCollapsedCategories || [],
        nominasLabelFilter: user?.nominasLabelFilter
      };
      let jsonString = JSON.stringify(userData);

      localStorage.setItem("user", jsonString);

      dispatch(login(userData));

      if (lastLocation === null) history?.push("/");
      else history?.push(lastLocation.pathname);
    }
  };
};

export const startLogout = () => {
  return (dispatch) => {
    localStorage.clear();
    localStorage.removeItem("token");
    dispatch(logout());
  };
};

const logout = () => ({ type: types.authLogout });
