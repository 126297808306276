import { types } from "../types/types";

const initialState = {
  showModal: {},
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getModal:
      return {
        ...state,
        showModal: { ...action.payload },
      };
    case types.authLogout:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
