import { types } from "../types/types";

const initialState = {
  getTransactions: [],
  getTransactionsTotalDocs: 0,
  getTransactionsFilter: {},
  getTransactionsBanks: {},
  updateTransactions: false
};

export const conciliacionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getTransactions:
      return {
        ...state,
        getTransactions: action?.payload || initialState.getTransactions,
      };
    case types.getTransactionsTotalDocs:
      return {
        ...state,
        getTransactionsTotalDocs: action.payload,
      };
    case types.getTransactionsFilter:
      return {
        ...state,
        getTransactionsFilter: action?.payload || initialState.getTransactionsFilter,
      };
    case types.getTransactionsBanks:
      return {
        ...state,
        getTransactionsBanks: action?.payload || initialState.getTransactionsBanks,
      };  
    case types.updateConciliacion:
      return {
        ...state,
        updateTransactions: action.payload
      }
    default:
      return state;
  }
};
