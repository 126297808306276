import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { uiReducer } from "../reducers/uiReducer";
import { liquidezReducer } from "../reducers/liquidezReducer";
import { creditosReducer } from "../reducers/creditosReducer";
import { generalReducer } from "../reducers/generalReducer";
import { conexionesReducer } from "../reducers/conexionesReducer";
import { proveedoresReducer } from "../reducers/proveedoresReducer";
import { dashboardReducer } from "../reducers/dashboardReducer";
import { authReducer } from "../reducers/authReducer";
import { flujoCajaReducer } from "../reducers/flujoCajaReducer";
import { documentosTributariosReducer } from "../reducers/documentosTributariosReducer";
import { agendaReducer } from "../reducers/agendaReducer";
import { companyListReducer } from "../reducers/companyListReducer";
import { alertStateReducer } from "../reducers/alertReducer";
import { fcDetailReducer } from "../reducers/fcDetailReducer";
import { modalReducer } from "../reducers/modalReducer";
import { manualDocumentReducer } from "../reducers/manualDocumentReducer";
import { initialBalanceReducer } from "../reducers/initialBalanceReducer";
import { tabsReducer } from "../reducers/tabsReducer";
import { openSidebarReducer } from "../reducers/openSidebarReducer";
import { newModalReducer } from "../reducers/newModalReducer";
import { userReducer } from "../reducers/usersReducer";
import { notificationReducer } from "../reducers/notificationReducer";
import { conciliacionReducer } from "../reducers/conciliacionReducer";
import { onboardingReducer } from "../reducers/onboardingReducer";
import { enhancedIconsReducer } from "../reducers/enhancedIcons";
import { eerrReducer } from "../reducers/eerrReducer";
import { botLastUpdateDateReducer } from '../reducers/botLastUpdateDateReducer'
import { lastBankUpdatedReducer } from '../reducers/lastBankUpdatedReducer'
import { cobranzaReducer } from "../reducers/cobranzaReducer";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const reducers = combineReducers({
  ui: uiReducer,
  flujocaja: flujoCajaReducer,
  creditos: creditosReducer,
  conexiones: conexionesReducer,
  proveedores: proveedoresReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  general: generalReducer,
  documentostributarios: documentosTributariosReducer,
  liquidez: liquidezReducer,
  companyList: companyListReducer,
  alertState: alertStateReducer,
  agenda: agendaReducer,
  fcDetail: fcDetailReducer,
  modal: modalReducer,
  manualDocument: manualDocumentReducer,
  initialBalance: initialBalanceReducer,
  tabs: tabsReducer,
  openSidebar: openSidebarReducer,
  newModal: newModalReducer,
  users: userReducer,
  notifications: notificationReducer,
  conciliacion: conciliacionReducer,
  onboarding: onboardingReducer,
  enhanced: enhancedIconsReducer,
  eerr: eerrReducer,
  botLastUpdateDate: botLastUpdateDateReducer,
  lastBankUpdatedOnCompany: lastBankUpdatedReducer,
  cobranza: cobranzaReducer
});
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);

export default () => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);
  return { store, persistor };
};
