import React, { useEffect, useState } from "react";
import { HashRouter, BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { startChecking } from "./redux/actions/auth";
import { importNewModal } from "./redux/actions/newModal";
import {
  createClient,
  dedupExchange,
  cacheExchange,
  fetchExchange,
  Provider,
  defaultExchanges,
  subscriptionExchange,
} from "urql";
import { authExchange } from "@urql/exchange-auth";
import {
  getAuth,
  addAuthToOperation,
  didAuthError,
  willAuthError,
} from "./graphql/logIn";
import { SubcribeWS } from './graphql/botsWs'
import "./scss/style.scss";
import Alert from "./components/alert";
import Modal from "./components/modal";
import NewModal from "./components/newModal";
import { SubscriptionClient } from "subscriptions-transport-ws";
import Intercom from "react-intercom";
import PepperLogo from "./assets/svg/Pepper/Logo_Pepper_Horizontal.svg";
import { Plans } from "./utils/plans";
import { UserContext } from "./context";
import { useContext } from "react";
import { Roles } from "./utils/roles";
import WelcomeModal from "./containers/welcomeModal";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Layout = React.lazy(() => import("./containers/Layout"));
//const Login = React.lazy(() => import("./components/login/Login"));
const SignIn = React.lazy(() => import("./components/newOnboarding/SignIn"));
const SignUp = React.lazy(() => import("./components/newOnboarding/SignUp"));
const CreateCompany = React.lazy(() => import("./components/newOnboarding/CreateCompany"));
const ConnectSII = React.lazy(() => import("./components/newOnboarding/ConnectSII"));
const ConnectBalance = React.lazy(() => import("./components/newOnboarding/ConnectBalance"));
const CashFlowQuestions = React.lazy(() => import("./components/newOnboarding/CashFlowQuestions"));
const OnboardingWaitingRoom = React.lazy(() => import("./components/newOnboarding/OnboardingWaitingRoom"));
const ForgotPassword = React.lazy(() => import("./components/newOnboarding/ForgotPassword"));
const InvitedUser = React.lazy(() => import("./components/newOnboarding/InvitedUser"));
const ResetPassword = React.lazy(() => import("./components/newOnboarding/ResetPassword"));

const subscriptionClient = new SubscriptionClient(
  process.env.REACT_APP_WS_URL,
  { reconnect: true }
);
const client = createClient({
  url: process.env.REACT_APP_API_URL,
  exchanges: [
    dedupExchange,
    cacheExchange,
    authExchange({
      addAuthToOperation,
      willAuthError,
      didAuthError,
      getAuth,
    }),
    fetchExchange,
    ...defaultExchanges,
    subscriptionExchange({
      forwardSubscription: (operation) => subscriptionClient.request(operation),
    }),
  ],
});

function App() {
  const dispatch = useDispatch();
  const { uid, name, email, surname, roles, phone} = useSelector((state) => state.auth);
  const { showModal } = useSelector((state) => state.newModal);
  const { selectedCompany } = useSelector((state) => state.companyList);
  const { companyId } = useSelector((state) => state.onboarding);
  const { alertState } = useSelector((state) => state.alertState);

  const [currentCompanyId, setCurrentCompanyId] = useState(companyId)
  const [bots, setBots] = useState([])

  async function trackUser(heap, userId) {
  
    if (userId) {
       heap.identify(userId);
       heap.addUserProperties({
         fullName: name + ' ' + surname ,
         name, 
         surname, 
         email, 
         roles,
         plan: selectedCompany?.plan,
        });
        
       heap.track('User', { userId })
    }
  }

  useEffect(() => {
    trackUser(window.heap, uid);  
  }, [uid])

  useEffect(() => {
    if (companyId) setCurrentCompanyId(companyId);  
  }, [companyId])
  
  useEffect(() => {
    dispatch(startChecking());
  }, [dispatch]);

  const isAuthenticated = !!uid;
  const userIntercom = isAuthenticated
    ? {
        user_id: uid,
        email: email,
        name: name + " " + surname,
        phone,
        company: { 
          company_id: selectedCompany?.id || "",
          name: selectedCompany?.name || "",
          plan: selectedCompany?.plan && selectedCompany?.plan[0]
        },
        job_title: selectedCompany?.name || "",
        workspace_id: selectedCompany?.plan && !selectedCompany.plan.includes(Plans.blocked) && selectedCompany?.suscriptionId ? 'DE PAGO' : null,
        article_id: selectedCompany?.plan && selectedCompany?.plan[0]
      }
    : {}

  const res = SubcribeWS(currentCompanyId)

  useEffect(() => {
    if (!res?.fetching && res?.data) {
      const [bot] = res.data
      setBots(bots.push(bot))
    }
  }, [res?.data])

  useEffect(() => {
    if (selectedCompany?.plan){

      const currentURL = window.location.href
      const isSuscriptionPage = String(currentURL).includes('/suscripcion') 
      
      if (isSuscriptionPage) return

      const isBlocked = selectedCompany.plan.includes(Plans.blocked)

      if (isBlocked) {
        const blockModal = {
          show: true,
          hideAcceptButton: true,
          title: `Tu periodo de prueba se acabó`,
          content:  `${selectedCompany?.name}`,
          subcontent: `Esperamos que hayas disfrutado de tu experiencia en Pepper. Si deseas seguir, cierra esta ventana y da click en "Contratar Pepper".`,
          onCancelText: `Cerrar`
        }
        if(String(currentURL).includes('/logout') ) return
        dispatch(importNewModal(blockModal))
      }
    }
  }, [selectedCompany?.id])

  const currentURL = window.location.hash

  if (currentURL.startsWith('#/')) {
    window.history.pushState({}, '', currentURL.replace('#', ''))
  }

  const isUserSCFO = (roles || []).includes(Roles.USER_SMARTCFO)

  return (
    <div className="pepperApp">
      {/* <IntercomProvider appId={c60z69h4} autoBoot {...userIntercom} /> */}
      { isUserSCFO === false && <Intercom appID="c60z69h4" {...userIntercom} /> }
      <Alert alertState={alertState} />
      <Provider value={client}>
        <Modal />
        <WelcomeModal />
        <NewModal>{showModal?.children}</NewModal>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            { !isAuthenticated ? (
              <Switch>
                <Redirect from="/" to="/signin" exact />
                <Redirect from="/logout" to="/signin" />
                <Redirect from="/dashboard" to="/signin"/>
                <Redirect from="/flujo_caja" to="/signin"/>
                <Redirect from="/conciliacion" to="/signin"/>
                <Redirect from="/pago_proveedores" to="/signin"/>
                <Redirect from="/documentos_tributarios" to="/signin"/>
                <Redirect from="/creditos" to="/signin"/>
                <Redirect from="/conexiones" to="/signin"/>
                <Redirect from="/agenda" to="/signin"/>
                <Redirect from="/reportes" to="/signin"/>
                <Redirect from="/configuracion" to="/signin"/>
                <Redirect from="/eerr" to="/signin"/>
                <Route name="Login" path="/signin" render={(props) => <SignIn {...props} />} />
                <Route name="Tryout" path="/tryout" render={(props) => <SignUp {...props} />} />
                <Route name="SignUp" path="/signup" render={(props) => <SignUp {...props} />} />
                <Route name="CreateCompany" path="/company" render={(props) => <CreateCompany {...props} />} />
                <Route name="SIIConnect" path="/cashflow-documents" render={(props) => <ConnectSII {...props} />} />
                <Route name="ConnectBank" path="/cashflow-bankBalance" render={(props) => <ConnectBalance {...props} />} />
                <Route name="CashflowQuestions" path="/cashflow-additional" render={(props) => <CashFlowQuestions {...props} />} />
                <Route name="OnboardingWaitingRoom" path="/waiting-room" render={(props) => <OnboardingWaitingRoom {...props} bots={bots} />} />
                <Route name="ForgotPassword" path="/forgot-password" render={(props) => <ForgotPassword {...props} />} />
                <Route name="InvitedUser" path="/create-password" render={(props) => <InvitedUser {...props} />} />
                <Route name="ResetPassword" path="/reset-password" render={(props) => <ResetPassword {...props} />} />
              </Switch>
            ) : (
              <Switch>
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <Layout {...props} />}
                />
              </Switch>
            )}
          </React.Suspense>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
