import { types } from "../types/types";

const initialState = {
  dataConexiones: [],
  dataInsertBancarias: {},
  updatePasswordNeeded: {},
  allConnections: []
};

export const conexionesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getDataConexiones:
      return {
        ...state,
        ...{ dataConexiones: action.payload, allConnections: action.payload } ,
      };
    case types.getInsertConexionBancaria:
      return {
        ...state,
        dataInsertBancarias: { ...action.payload },
      };
    case types.authLogout:
      return {
        ...initialState,
      };
    case types.getUpdatePasswordNeeded:
      return {
        ...state,
        updatePasswordNeeded: { ...action.payload },
      };
    default:
      return state;
  }
};
