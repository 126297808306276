import { types } from "../types/types";

const initialState = {
  companyList: {},
  selectedCompany: {},
  getLoadingState: {},
  updateCompanyNeeded: {}
};

export const companyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getCompanyList:
      return {
        ...state,
        companyList: [...action.payload],
      };
    case types.selectedCompany:
      return {
        ...state,
        selectedCompany: {
          ...action.payload,
        },
      };
    case types.getLoadingState:
      return {
        ...state,
        getLoadingState: {
          ...action.payload,
        },
      };
    case types.authLogout:
      return {
        ...initialState,
      };
    case types.getUpdateCompanyNeeded:
      return {
        ...state,
        updateCompanyNeeded: { ...action.payload },
      };
    default:
      return state;
  }
};
