import { types } from "../types/types";

const initialState = {
  liquidez: {},
  doctrib: [],
  documentsFilters: {},
  getLastUpdate: "",
  totalDocs: 0,
  selectedDocuments: [],
  documentTypes: "",
  selectedType: "ALL",
  excludeDocumentCodes: [],
  updateDocuments: {},
  documentTypesState: "NEW",
  cleanSelectedDocuments: false
};

export const documentosTributariosReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getDocTributarios:
      return {
        ...state,
        getLastUpdate:
          action.payload?.paginatedDocumentsByCompany?.getLastUpdate,
        doctrib: [...(action.payload?.paginatedDocumentsByCompany?.docs || [])],
        totalDocs: action.payload?.paginatedDocumentsByCompany?.totalDocs || 0,
      };
    case types.getDocumentsFilter:
      return {
        ...state,
        documentsFilter: action?.payload || initialState.documentsFilters,
      };
    case types.documentTypes:
      return {
        ...state,
        documentTypes: action?.payload || initialState.documentTypes,
      };
    case types.selectedType:
      return {
        ...state,
        selectedType: action?.payload || initialState.selectedType,
      };
    case types.getSelectedDocuments:
      return {
        ...state,
        selectedDocuments: action?.payload || initialState.selectedDocuments,
      };
    case types.getExcludeDocumentCodes:
      return {
        ...state,
        excludeDocumentCodes:
          action?.payload || initialState.excludeDocumentCodes,
      };
    case types.getUpdateDocuments:
      return {
        ...state,
        updateDocuments: { ...action.payload },
      };
    case types.authLogout:
      return {
        ...initialState,
      };
    case types.updateDocumentTypesFilter:
      return {
        ...state,
        documentTypesState: action.payload,
      };
    case types.cleanSelectedDocuments:
      return {
        ...state,
        cleanSelectedDocuments: action.payload,
      };
    default:
      return state;
  }
};
