import { useQuery, useMutation } from "urql";

const PROVIDERS = `
query Nominas($companyId: String!) {
    Nominas(companyId: $companyId) {
      id
      providers {
        rut
        name
        inAgenda
        nSelectedDTEs
        DTEs {
          id
          documentNuber
          documentCode
          detail
          amount
          expiration
          selected
          daysToExpire
          emition
          remaining
          description {
            item
            description
            quantity
            unit
            unit_price
            total_price
            discount
            discount_percentage
          }
          amountSelected
          inform {
            amountInform
            isInform
            remaining
          }
        }
      }
      excludedProviders {
        rut
        name
      }
      partialAmount {
        idDocument
        originalAmount
        remaining
        amountSelected
      }
    }
  }  
`;

export const GetProvidersData = (companyId) => {
  const [result, reexecuteQuery] = useQuery({
    query: PROVIDERS,
    variables: { companyId },
  });
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteQuery };
};

const SELECTALLNOMINASPARTIAL = `mutation SelectAllNominasPartial($companyId: String!, $selected: Boolean!, $idProvider: String!, $all: Boolean!) {
  SelectAllNominasPartial(companyId: $companyId, selected: $selected, idProvider: $idProvider, all: $all) {
    id
    selectedDTEs
    providers {
      rut
      name
      inAgenda
      DTEs {
        id
        documentNuber
        detail
        amount
        expiration
        selected
        daysToExpire
        emition
      }
    }
    excludedProviders {
      rut
      name
    }
    partialAmount {
      idDocument
      originalAmount
      remaining
      amountSelected
    }
  }
}`;

export const SelectAllNominasPartial = () => {
  const [selectNominas, selectAllNominas] = useMutation(
    SELECTALLNOMINASPARTIAL
  );
  return selectAllNominas;
};

const EXCLUDEPROVIDER = `mutation ExcludeProvider($input: ExcludeInput!) {
  excludeProvider(input: $input) {
    id
    selectedDTEs
    providers {
      rut
      name
      inAgenda
      DTEs {
        id
        documentNuber
        detail
        amount
        expiration
        selected
        daysToExpire
        emition
      }
    }
    excludedProviders {
      rut
      name
    }
  }
}`;

export const ExcludeProvider = () => {
  const [exclude, excludeProvider] = useMutation(EXCLUDEPROVIDER);
  const { data, fetching, error } = exclude;
  return { data, fetching, error, excludeProvider };
};

const SELECTNOMINASPARTIAL = `mutation SelectNominasPartial($input: SelectedInputPartial!) {
  SelectNominasPartial(input: $input) {
    id
    selectedDTEs
    providers {
      rut
      name
      inAgenda
      DTEs {
        id
        documentNuber
        detail
        amount
        expiration
        selected
        daysToExpire
        emition
      }
    }
    excludedProviders {
      rut
      name
    }
    partialAmount {
      idDocument
      originalAmount
      remaining
      amountSelected
    }
  }
}`;

export const SelectNominasPartial = () => {
  const [nominas, selectNominas] = useMutation(SELECTNOMINASPARTIAL);
  return selectNominas;
};

const SETTLETAXDOCUMENTNOMINAS = `mutation SETTLE_TAX_DOCUMENT_NOMINAS(
  $id: String!
  $isPaid: Boolean!
  $remaining: String!
  $amount: String!
) {
  settleTaxDocumentNominas(id: $id, isPaid: $isPaid, remaining: $remaining, amount: $amount) {
    id
  }
}`;

export const SettleTaxDocumentNominas = () => {
  const [settleTaxDocument, settleTaxDocumentNominas] = useMutation(
    SETTLETAXDOCUMENTNOMINAS
  );
  return settleTaxDocumentNominas;
};

const IMPORTNOMINAS = `mutation ImportNominas(
  $companyId: String!
  $input: [ImportNominaInput!]!
) {
  importNominas(companyId: $companyId, input: $input)
}`;

export const ImportNominas = () => {
  const [result, reexecuteMutation] = useMutation(IMPORTNOMINAS);
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteMutation };
};

const BANKSLIST = `query bankPasswors($companyId: String!) {
  bankPasswords(companyId: $companyId) {
    id
    name
    templateId
  }
}`;

export const BanksList = (companyId) => {
  const [result, reexecuteQuery] = useQuery({
    query: BANKSLIST,
    variables: { companyId },
  });
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteQuery };
};

const INFORMPROVIDERS = `mutation InformNominas(
  $companyId: String!
  $bankId: String!
  $masivo: Boolean!
) {
  informNominas(companyId: $companyId, bankId: $bankId, masivo: $masivo)
}`;

export const InformProviders = () => {
  const [result, executeMutation] = useMutation(INFORMPROVIDERS)
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation }
};

