import { importAlertState } from "../../redux/actions/alert";
import { importUpdateCashflowNeeded } from "../../redux/actions/flujocaja";
import { importModal } from "../../redux/actions/modal";
import moment from "moment";
import { moneyToNumber, toMoney, stringToNumber } from "../../utils/formatter";

export const UpdatePoints = async (
  dispatch,
  showModal,
  variables,
  response,
  partialPayoff,
  nan,
  element,
  setPartialPayoff,
  postponeDateByDate,
  postponeDateByDays,
  setPostponeDateByDate,
  setPostponeDateByDays,
  setTypePostponeDate,
  document,
  setDocument,
  factorizePayoff,
  setFactorizePayoff,
  nin,
  setLoading,
  openSidebar
) => {
  dispatch(importModal({ show: false }))
  if (showModal?.key === "payoff") {
    variables.input.settled = true;
    dispatch(
      importAlertState({
        content: "Estamos actualizando sus datos, por favor espere",
        state: "loading",
        duration: 10,
      })
    );

    return await response(variables)
  }
  if (showModal?.key === "partialPayoff") {
    const orderByAmount = showModal.value.sort((a, b) => {
      if (a.amount > b.amount) {
        return 1;
      }
      if (a.amount < b.amount) {
        return -1;
      } else {
        return 0;
      }
    });
    if (
      partialPayoff &&
      stringToNumber(partialPayoff) > orderByAmount[0].amount
    ) {
      dispatch(
        importAlertState({
          content: `El nuevo monto no puede ser superior a ${toMoney(
            orderByAmount[0].amount
          )}`,
          state: "error",
          duration: 3,
        })
      );
      setLoading(false);
    } else if (
      partialPayoff &&
      nan === false &&
      stringToNumber(partialPayoff) <= orderByAmount[0].amount
    ) {
      dispatch(
        importAlertState({
          content: "Estamos actualizando sus datos, por favor espere",
          state: "loading",
          duration: 10,
        })
      );
      if (variables.input.amount === 0) variables.input.settled = true;
      if (!element.amountBeforePartialPayoff)
        variables.input.amountBeforePartialPayoff = element.amount;
      const { data, error, ...rest } = await response(variables);
      if (data) {
        dispatch(
          importAlertState({
            content: "Datos actualizados correctamente",
            state: "success",
            duration: 3,
          })
        );
        setPartialPayoff();
        dispatch(importUpdateCashflowNeeded({ value: true, reload: null }));
      }
      if (error) {
        dispatch(
          importAlertState({
            content:
              "Ha ocurrido un error en la operación, porfavor vuelva a intentarlo más tarde",
            state: "error",
            duration: 5,
          })
        );
        setLoading(false);
        setPartialPayoff();
      }
    } else {
      setLoading(false)
    }
  }
  if (showModal?.key === "postpone") {
    if (
      postponeDateByDate?.date !== null ||
      (postponeDateByDays?.days !== null && postponeDateByDays?.days > 0)
    ) {
      dispatch(
        importAlertState({
          content: "Estamos actualizando sus datos, por favor espere",
          state: "loading",
          duration: 10,
        })
      );
      const { data, error, ...rest } = await response(variables);
      if (data) {
        dispatch(
          importAlertState({
            content: "Datos actualizados correctamente",
            state: "success",
            duration: 3,
          })
        );
        setPostponeDateByDate({ date: null });
        setPostponeDateByDays({ days: null });
        dispatch(importUpdateCashflowNeeded({ value: true, reload: null }));
        setTypePostponeDate({ type: "date" });
      }
      if (error) {
        dispatch(
          importAlertState({
            content:
              "Ha ocurrido un error en la operación, porfavor vuelva a intentarlo más tarde",
            state: "error",
            duration: 5,
          })
        );
        setLoading(false);
        setTypePostponeDate({ type: "date" });
      }
    } else {
      dispatch(
        importAlertState({
          content: "Debe ingresar un valor",
          state: "error",
          duration: 5,
        })
      );
      setLoading(false);
    }
  }
  if (showModal?.key === "revertPayoff") {
    if (element.amountBeforePartialPayoff) {
      variables.input.amount = element.amountBeforePartialPayoff;
    }
    else if (element.originalAmount){
      variables.input.amount = element.originalAmount
    }
    variables.input.settled = false;
    dispatch(
      importAlertState({
        content: "Estamos actualizando sus datos, por favor espere",
        state: "loading",
        duration: 10,
      })
    );
    const { data, error, ...rest } = await response(variables);
    if (data) {
      dispatch(
        importAlertState({
          content: "Datos actualizados correctamente",
          state: "success",
          duration: 3,
        })
      );
      dispatch(importUpdateCashflowNeeded({ value: true, reload: null }));
    }
    if (error) {
      dispatch(
        importAlertState({
          content:
            "Ha ocurrido un error en la operación, porfavor vuelva a intentarlo más tarde",
          state: "error",
          duration: 5,
        })
      );
      setLoading(false);
      setPartialPayoff();
    }
  }
  if (showModal?.key === "chequePayoff") {
    let totalAmount = 0;
    if (document.length < 1) {
      dispatch(
        importAlertState({
          content: "Debe contener almenos un cheque",
          state: "error",
          duration: 3,
        })
      );
      setLoading(false);
    } else {
      let err = document.some(
        (document) =>
          !document.numberCheque ||
          !document.fecha ||
          !document.partialAmount ||
          parseInt(document.partialAmount) < 1
      );
      if (err) {
        dispatch(
          importAlertState({
            content: "Todos los campos del cheque son obligatorios",
            state: "error",
            duration: 3,
          })
        );
        setLoading(false);
      }
      if (!err) {
        document.forEach((document) => {
          totalAmount = totalAmount + parseInt(document.partialAmount);
        });
        if (totalAmount > showModal.amount) {
          dispatch(
            importAlertState({
              content: "El monto es superior al seleccionado",
              state: "error",
              duration: 3,
            })
          );
          setLoading(false);
        } else {
          let cheques = [];
          document.forEach((doc) =>
            cheques.push({
              numberCheque: parseFloat(doc.numberCheque),
              partialAmount: parseFloat(doc.partialAmount),
              fecha: moment(doc.fecha).toDate(),
            })
          );
          
          variables.input.amount = showModal.amount - totalAmount; 
          variables.input.cashflowAreaIdcheque = openSidebar.isReceived ? "5e59495c8194638ee0b6d607": "5e59495c8194638ee0b6d603";
          variables.input.cheques = cheques;
          if (totalAmount === showModal.amount) {
            variables.input.settled = true;
          }
          if (!element.amountBeforePartialPayoff) {
            variables.input.amountBeforePartialPayoff = element.amount;
          }
          dispatch(
            importAlertState({
              content: "Estamos actualizando sus datos, por favor espere",
              state: "loading",
              duration: 10,
            })
          );

          const { data, error, ...rest } = await response(variables);
          if (data) {
            dispatch(
              importAlertState({
                content: "Datos actualizados correctamente",
                state: "success",
                duration: 3,
              })
            );
            setDocument([
              {
                partialAmount: null,
                numberCheque: null,
                fecha: null,
                key: 0,
              },
            ]);
            dispatch(importUpdateCashflowNeeded({ value: true, reload: null }));
          }
          if (error) {
            dispatch(
              importAlertState({
                content:
                  "Ha ocurrido un error en la operación, porfavor vuelva a intentarlo más tarde",
                state: "error",
                duration: 5,
              })
            );
            setLoading(false);
            setDocument([
              {
                partialAmount: showModal?.amount,
                numberCheque: null,
                fecha: null,
                key: 0,
              },
            ]);
          }
        }
      }
    }
  }
  if (showModal?.key === "factorize") {
    variables.input.amountBeforeFactorized = element.amount;
    if (moneyToNumber(factorizePayoff) < 1 || nin === true) {
      dispatch(
        importAlertState({
          content: "El monto no puede ser 0 o encontrarse vacío.",
          state: "error",
          duration: 3,
        })
      );
      setLoading(false);
    }
    if (moneyToNumber(factorizePayoff) > showModal.amount) {
      dispatch(
        importAlertState({
          content: "El monto no puede ser superior al monto seleccionado.",
          state: "error",
          duration: 3,
        })
      );
      setLoading(false);
    } else if (factorizePayoff && nin === false) {
      dispatch(
        importAlertState({
          content: "Estamos actualizando sus datos, por favor espere",
          state: "loading",
          duration: 10,
        })
      );
      variables.input.amount = 0
      const { data, error, ...rest } = await response(variables);
      if (data) {
        dispatch(
          importAlertState({
            content: "Datos actualizados correctamente",
            state: "success",
            duration: 3,
          })
        );
        setFactorizePayoff();
        dispatch(importUpdateCashflowNeeded({ value: true, reload: null }));
      }
      if (error) {
        dispatch(
          importAlertState({
            content:
              "Ha ocurrido un error en la operación, porfavor vuelva a intentarlo más tarde",
            state: "error",
            duration: 5,
          })
        );
        setLoading(false);
        setFactorizePayoff();
      }
    }
  }
  if (showModal?.key === "revertFactorize") {
    variables.input.amountBeforeFactorized = null;
    variables.input.amount = element.amountBeforeFactorized
      ? element.amountBeforeFactorized
      : element.amount;
    variables.input.cashflowAreaId = "5e5948948194638ee0b6d58f";
    dispatch(
      importAlertState({
        content: "Estamos actualizando sus datos, por favor espere",
        state: "loading",
        duration: 10,
      })
    );
    const { data, error, ...rest } = await response(variables);
    if (data) {
      dispatch(
        importAlertState({
          content: "Datos actualizados correctamente",
          state: "success",
          duration: 3,
        })
      );
      dispatch(importUpdateCashflowNeeded({ value: true, reload: null }));
    }
    if (error) {
      dispatch(
        importAlertState({
          content:
            "Ha ocurrido un error en la operación, porfavor vuelva a intentarlo más tarde",
          state: "error",
          duration: 5,
        })
      );
      setLoading(false);
    }
  }
  if (showModal?.key === "partialPay") {
  }
};

export const updateProviderPartialPayoff = async (
  dispatch,
  selectNominasPartial,
  providerPartialPayoff,
  companyId,
  showModal,
  setLoading
) => {
  if (providerPartialPayoff) {
    dispatch(
      importAlertState({
        content: "Estamos actualizando sus datos, por favor espere",
        state: "loading",
        duration: 5,
      })
    );
    const result = await selectNominasPartial({
      input: {
        amountSelected: parseInt(stringToNumber(providerPartialPayoff)),
        companyId,
        idDocument: showModal?.data?.id,
        originalAmount: showModal?.data?.amount,
        remaining: showModal?.data?.remaining,
        selected: true,
      },
    });
    if (result?.data) {
      dispatch(
        importAlertState({
          content: "Datos actualizados correctamente",
          state: "success",
          duration: 3,
        })
      );
      dispatch(importUpdateCashflowNeeded({ value: true, reload: null }));
      dispatch(importModal({ show: false }));
    }
    if (result?.error) {
      dispatch(
        importAlertState({
          content:
            "Ha ocurrido un error en la operación, porfavor vuelva a intentarlo más tarde",
          state: "error",
          duration: 5,
        })
      );
      setLoading(false);
      dispatch(importModal({ show: false }));
    }
  } else {
    dispatch(
      importAlertState({
        content: "Debe ingresar un valor",
        state: "error",
        duration: 3,
      })
    );
    setLoading(false);
  }
};

export const updateSettleTaxDocument = async (
  dispatch,
  settleTaxDocumentNominas,
  showModal,
  providerChangeAmount,
  setLoading
) => {
  if (providerChangeAmount) {
    dispatch(
      importAlertState({
        content: "Estamos actualizando sus datos, por favor espere",
        state: "loading",
        duration: 5,
      })
    );
    const result = await settleTaxDocumentNominas({
      id: showModal?.data?.id,
      isPaid: false,
      remaining: stringToNumber(`${providerChangeAmount}`),
    });
    if (result?.data) {
      dispatch(
        importAlertState({
          content: "Datos actualizados correctamente",
          state: "success",
          duration: 3,
        })
      );
      dispatch(importUpdateCashflowNeeded({ value: true, reload: null }));
      dispatch(importModal({ show: false }));
    }
    if (result?.error) {
      dispatch(
        importAlertState({
          content:
            "Ha ocurrido un error en la operación, porfavor vuelva a intentarlo más tarde",
          state: "error",
          duration: 5,
        })
      );
      setLoading(false);
      dispatch(importModal({ show: false }));
    }
  } else {
    dispatch(
      importAlertState({
        content: "Debe ingresar un valor",
        state: "error",
        duration: 5,
      })
    );
    setLoading(false);
  }
};
