export const Plans = {
  free : 'free',
  pepper : 'pepper',
  trial : 'trial',
  smartcfo : 'smartcfo',
  nominas : 'nominas',
  facturacion : 'facturacion',
  despega : 'despega',
  inicia : 'inicia',
  bloqueado : 'bloqueado',
  blocked : 'blocked',
  smartcfopro : 'smartcfopro',
  smartcfofull : 'smartcfofull',
  plan_base : 'plan_base',
  flujo_de_caja : 'flujo_de_caja',
  pago_a_proveedores : 'pago_a_proveedores',
  estado_de_resultados : 'estado_de_resultados',
  cuenta_corriente : 'cuenta_corriente',
  founder: 'FOUNDER'
}

export const FULL_ACCESS_PLANS = [
  Plans.pepper,
  Plans.trial,
  Plans.smartcfo,
  Plans.smartcfofull,
  Plans.smartcfopro,
]

export const PLANS_SMARTCFO = [
  Plans.smartcfo,
  Plans.smartcfofull,
  Plans.smartcfopro,
  Plans.free,
  Plans.despega,
  Plans.inicia,
]