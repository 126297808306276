import { types } from "../types/types";

const initialState = {
  barDashboard: {},
  panelesDashboard: {},
  barDashboardLine: {},
  lineDashboard: {},
  notificacionesDashboard: [],
  cobranzaDashboard: {},
  newCobranza: {},
  selectedClient: {},
  selectedFilter: {},
  selectedCompany: {},
  sidebarMinimized: {},
  updateDashboardNeeded: {},
  conciliationRatio: { ratio: 0, update: true },
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.sideBarMinimized:
      return {
        ...state,
        sidebarMinimized: {
          ...action.payload,
        },
      };
    case types.getBarDashboard:
      return {
        ...state,
        barDashboard: {
          ...action.payload,
        },
      };
    case types.selectedClient:
      return {
        ...state,
        selectedClient: {
          ...action.payload,
        },
      };
    case types.selectedFilter:
      return {
        ...state,
        selectedFilter: {
          ...action.payload,
        },
      };
    case types.setBarDashboardLine:
      return {
        ...state,
        barDashboardLine: action.payload,
      };
    case types.getPanelesDashboard:
      return {
        ...state,
        panelesDashboard: {
          ...action.payload,
        },
      };
    case types.getUpdateDashboardNeeded:
      return {
        ...state,
        updateDashboardNeeded: { ...action.payload },
      };  
    case types.getBarDashboardLine:
      return {
        ...state,
        barDashboardLine: {
          ...action.payload,
        },
      };
    case types.getLineDashboard:
      return {
        ...state,
        lineDashboard: action.payload,
      };
    case types.getNotificacionesDashboard:
      return {
        ...state,
        notificacionesDashboard: [...action.payload],
      };
    case types.getCobranzaDashboard:
      return {
        ...state,
        cobranzaDashboard: { ...action.payload },
      };
    case types.getCobranzaApi:
      return {
        ...state,
        newCobranza: { ...action.payload },
      };
    case types.authLogout:
      return {
        ...initialState,
      };
    case types.conciliationRatio:
      return {
        ...state,
        conciliationRatio: { ratio: action.payload, update: false },
      };
    case types.conciliationRatioUpdate:
      return {
        ...state,
        conciliationRatio: {
          ratio: state.conciliationRatio.payload,
          update: true,
        },
      };
    default:
      return state;
  }
};
