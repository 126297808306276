import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { importModal } from "../../redux/actions/modal";
import { PostUpdatePoint, SettlePoint } from "../../graphql/updateSource";
import { FindAndSettleDocumentsById, FindAndPartialSettleDocumentsById, ModifyDocument } from "src/graphql/documents";
import { setUpdateDocuments } from "src/redux/actions/documentostributarios";
import { updateConcilieantionRatio } from "src/redux/actions/dashboard";
import { importAlertState } from "../../redux/actions/alert";
import {
  UpdatePoints,
  updateProviderPartialPayoff,
  updateSettleTaxDocument,
} from "./functions";
import {
  formatMoney,
  moneyToNumber,
  stringToNumber,
  toMoney,
} from "../../utils/formatter";
import moment from "moment";
import {
  SelectNominasPartial,
  SettleTaxDocumentNominas,
} from "../../graphql/providers";
import {
  Button,
  Modal,
  Header,
  Icon,
  Input,
  Select,
  Card,
} from "semantic-ui-react";
import { Delete_Agenda } from "../../graphql/agenda";

import "../../scss/_semanticForms.scss";
import { importUpdateCashflowNeeded, importUpdateNeeded } from "src/redux/actions/flujocaja";

const POSIBLE_OPERATIONS = {
  payoff: 'payoff',
  partialPayoff: "partialPayoff"
}

export default function ModalFC() {
  const dispatch = useDispatch();
  const selectNominasPartial = SelectNominasPartial();
  const settleTaxDocumentNominas = SettleTaxDocumentNominas();
  const { settlePoint } = SettlePoint()
  const { showModal } = useSelector((state) => state.modal);
  const { openSidebar } = useSelector((state) => state.openSidebar);
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState([
    {
      partialAmount: showModal?.amount,
      numberCheque: null,
      fecha: null,
      key: counter + 1,
    },
  ]);
  const [lessDoc, setLessDoc] = useState(false);
  const [partialPayoff, setPartialPayoff] = useState();
  const [factorizePayoff, setFactorizePayoff] = useState(showModal.amount);
  const [providerPartialPayoff, setProviderPartialPayoff] = useState(0);
  const [providerChangeAmount, setProviderChangeAmount] = useState(
    showModal?.data?.remaining
  );
  const [typePostponeDate, setTypePostponeDate] = useState({
    type: "date",
  });
  const [postponeDateByDate, setPostponeDateByDate] = useState({
    date: null,
  });
  const [postponeDateByDays, setPostponeDateByDays] = useState({
    days: null,
  });
  const { selectedCompany } = useSelector((state) => state.companyList);
  const companyId = selectedCompany?.id;
  const { executeMutation: findAndSettleDocumentsById } =
    FindAndSettleDocumentsById();
  const { executeMutation: findAndPartialSettle } = FindAndPartialSettleDocumentsById()
  // const response = PostUpdatePoint();
  const response = ModifyDocument()
  const deleteAgenda = Delete_Agenda();
  let userId = JSON.parse(localStorage.getItem("user"));

  const orderByAmount = showModal?.value?.sort((a, b) => {
    if (a.amount > b.amount) {
      return 1;
    }
    if (a.amount < b.amount) {
      return -1;
    } else {
      return 0;
    }
  });

  const updateObligation = async () => {
    const nan = isNaN(stringToNumber(partialPayoff));
    const nin = isNaN(stringToNumber(factorizePayoff));

    if (POSIBLE_OPERATIONS.payoff === showModal.key) {
      dispatch(
        importAlertState({
          content: "Estamos actualizando sus datos, por favor espere",
          state: "loading",
          duration: 5,
        })
      );

      const points = showModal?.value?.filter((element) => element.id.includes('-') && !element.id.includes('-0'))

      for (let point of points) {
        const result = await settlePoint({ pointId: point.id || point._id, companyId })
      }

      const ids = showModal?.value?.map((element) => element.id.replace(/-\d+/g, ''))

      const { error, data } = await findAndSettleDocumentsById({ ids: ids });
      setLoading(false);
      dispatch(importModal({ show: false }))
      if (data) {
        dispatch(
          importAlertState({
            content: showModal?.value.length > 1
              ? `${showModal?.value.length} datos han sido actualizados correctamente`
              : 'Dato ha sido actualizado correctamente.',
            state: "success",
            duration: 3,
          })
        );
        dispatch(importUpdateCashflowNeeded({ value: true, reload: null }))
        dispatch(updateConcilieantionRatio());
        dispatch(setUpdateDocuments({ value: true }))
      }
      if (error) {
        dispatch(
          importAlertState({
            content:
              "Ha ocurrido un error en la operación, porfavor vuelva a intentarlo más tarde",
            state: "error",
            duration: 5,
          })
        );
      }
    } else if (POSIBLE_OPERATIONS.partialPayoff === showModal.key) {
      dispatch(
        importAlertState({
          content: "Estamos actualizando sus datos, por favor espere",
          state: "loading",
          duration: 5,
        })
      );

      const ids = showModal?.value?.map((element) => {
        return {
          id: element.id.replace("-0", ''),
          amount: parseInt(stringToNumber(partialPayoff))
        }
      })

      const { error, data } = await findAndPartialSettle({ ids: ids })
      setPartialPayoff()
      setLoading(false);
      dispatch(importModal({ show: false }))

      if (data) {
        dispatch(
          importAlertState({
            content: showModal?.value.length > 1
              ? `${showModal?.value.length} datos han sido actualizados correctamente`
              : 'Dato ha sido actualizado correctamente.',
            state: "success",
            duration: 3,
          })
        );
        dispatch(importUpdateCashflowNeeded({ value: true, reload: null }))
        dispatch(updateConcilieantionRatio());
        dispatch(setUpdateDocuments({ value: true }))
      }
      if (error) {
        dispatch(
          importAlertState({
            content:
              "Ha ocurrido un error en la operación, porfavor vuelva a intentarlo más tarde",
            state: "error",
            duration: 5,
          })
        );
      }
    } else {
      /** Método antiguo de saldado, saldado cheque, factorizar, cambios de fecha. */
      showModal?.value?.forEach(async (element, index) => {
        const newDate = postponeDateByDate?.date
          ? moment(postponeDateByDate?.date)._d
          : postponeDateByDays?.days
            ? moment(element?.date).add(postponeDateByDays?.days, "days")
            : element?.date;
        const newAmount = factorizePayoff
          ? moneyToNumber(factorizePayoff)
          : stringToNumber(partialPayoff) !== 0 && stringToNumber(partialPayoff)
            ? stringToNumber(partialPayoff)
            : postponeDateByDate.date
              ? element.amount
              : postponeDateByDays?.days
                ? element?.amount
                : // : element?.amount;
                0;
        // const variables = {
        //   companyId,
        //   id: element?.id,
        //   input: {
        //     documentType: element?.documentType,
        //     documentId: element?.documentId,
        //     amount: moneyToNumber(newAmount),
        //     companyId,
        //     settled: element?.settled,
        //     cheques:
        //       showModal.type === "factorize"
        //         ? null
        //         : partialPayoff
        //         ? { partialAmount: moneyToNumber(partialPayoff) }
        //         : null,
        //     date: newDate,
        //     type: showModal?.key,
        //     modifiedBy: userId?.uid,
        //     nameClient: element?.companyName,
        //     cashflowAreaId:
        //       showModal?.key === "factorize"
        //         ? "5e59495c8194638ee0b6d601"
        //         : showModal?.cashflowAreaId,
        //     name: element?.document?.meta?.documentId
        //       ? element?.document?.meta?.documentId
        //       : "",
        //   },
        // };

        const variables = {
          companyId,
          input: []
        }

        const modalResponse = await UpdatePoints(
          dispatch,
          showModal,
          variables,
          response,
          partialPayoff,
          nan,
          element,
          setPartialPayoff,
          postponeDateByDate,
          postponeDateByDays,
          setPostponeDateByDate,
          setPostponeDateByDays,
          setTypePostponeDate,
          document,
          setDocument,
          factorizePayoff,
          setFactorizePayoff,
          nin,
          setLoading,
          openSidebar
        );

        if (modalResponse && showModal?.value.length == index + 1) {
          const { data, error, ...rest } = modalResponse
          if (data) {
            dispatch(
              importAlertState({
                content: showModal?.value.length > 1
                  ? `${showModal?.value.length} datos han sido actualizados correctamente`
                  : 'Dato ha sido actualizado correctamente.',
                state: "success",
                duration: 3,
              })
            );
            dispatch(importUpdateCashflowNeeded({ value: true, reload: null }))

          }
          if (error) {
            dispatch(
              importAlertState({
                content:
                  "Ha ocurrido un error en la operación, porfavor vuelva a intentarlo más tarde",
                state: "error",
                duration: 5,
              })
            );
            setLoading(false);
          }
        }

      })
    }
  };

  const providerFunctions = () => {
    setLoading(true);
    if (showModal?.key === "partialPay") {
      updateProviderPartialPayoff(
        dispatch,
        selectNominasPartial,
        providerPartialPayoff,
        companyId,
        showModal,
        setLoading
      );
      setProviderPartialPayoff();
    }
    if (showModal?.key === "changeAmount") {
      updateSettleTaxDocument(
        dispatch,
        settleTaxDocumentNominas,
        showModal,
        providerChangeAmount,
        setLoading
      );
    }
  };

  useEffect(() => {
    setFactorizePayoff(showModal.amount);
  }, [showModal.amount]);

  useEffect(() => {
    if (showModal.show && showModal.amount) {
      setCounter(0);
      setDocument([
        {
          partialAmount: showModal?.amount,
          numberCheque: null,
          fecha: null,
          key: 0,
        },
      ]);
    }
    if (showModal?.show && showModal?.data) {
      setProviderChangeAmount(showModal?.data?.remaining);
    }
    setLoading(false);
  }, [showModal]);

  useEffect(() => {
    setLessDoc(false);
  }, [lessDoc]);

  const closeModal = () => {
    setDocument([
      {
        partialAmount: null,
        numberCheque: null,
        fecha: null,
        key: 0,
      },
    ]);
    setProviderPartialPayoff();
    dispatch(importModal({ show: false }));
  };

  const deleteRow = (key) => {
    const newCheques = document.filter((f) => f.key !== key);
    setDocument(newCheques);
  };

  const updateDocument = (value, key, type) => {
    document.map((element) => {
      if (element.key === key) {
        if (type === "numberCheque") {
          element.numberCheque = value;
        }
        if (type === "partialAmount") {
          element.partialAmount = value;
        }
        if (type === "fecha") {
          element.fecha = value;
        }
      }
    });
  };

  const deletingAgenda = async () => {
    dispatch(
      importAlertState({
        content: "Estamos actualizando sus datos, por favor espere",
        state: "loading",
        duration: 10,
      })
    );
    const result = await deleteAgenda({ id: showModal?.data?.id });
    if (result?.data) {
      dispatch(
        importAlertState({
          content: "Datos actualizados correctamente",
          state: "success",
          duration: 3,
        })
      );
      dispatch(importModal({ show: false }));
    }
    if (result?.error) {
      dispatch(
        importAlertState({
          content:
            "Ha ocurrido un error en la operación, porfavor vuelva a intentarlo más tarde",
          state: "error",
          duration: 5,
        })
      );
      dispatch(importModal({ show: false }));
    }
  };

  const optionsSelect = [
    { key: "date", text: "Seleccionar Fecha", value: "date" },
    { key: "days", text: "Ingresar Días", value: "days" },
  ];

  return (
    <>
      <Modal
        closeIcon
        open={showModal?.show}
        trigger={<div></div>}
        onClose={closeModal}
        onOpen={showModal?.onConfirm}
        size={showModal?.size || "small"}
        dimmer="blurring"
      >
        <Header
          as="h3"
          icon={showModal?.icon || null}
          content={showModal?.title || ""}
        />
        <Modal.Content>
          <div className="mb-3">
            {showModal?.content
              ? showModal?.content
              : showModal?.key === "chequePayoff"
                ? ""
                : ""}
          </div>
          {showModal?.key === "partialPayoff" ? (
            <>
              <div className="mb-3">
                <span>* </span>
                <span>Monto a saldar:</span>
              </div>
              <Input icon placeholder="0" iconPosition="left">
                <Icon name="dollar sign" />
                <input
                  type="amount"
                  onChange={(e) => setPartialPayoff(e.target.value)}
                  value={formatMoney(partialPayoff)}
                />
              </Input>
              {stringToNumber(partialPayoff) > orderByAmount[0]?.amount ? (
                <div className="mt-3">
                  <span className="text-danger">{`El nuevo monto debe tener un rango entre $ 0 y ${toMoney(
                    orderByAmount[0]?.amount
                  )}`}</span>
                </div>
              ) : (
                ""
              )}
            </>
          ) : showModal?.key === "postpone" ? (
            <>
              <Input action>
                <Select
                  selectOnBlur={false}
                  defaultValue="date"
                  options={optionsSelect}
                  onChange={(_, val) => {
                    if (val.value === "date") {
                      setTypePostponeDate({ type: val.value });
                      setPostponeDateByDays({
                        days: null,
                      });
                    } else {
                      setPostponeDateByDate({
                        date: null,
                      });
                      setTypePostponeDate({ type: val.value });
                    }
                  }}
                />
                <input
                  value={
                    typePostponeDate?.type === "days"
                      ? postponeDateByDays?.days
                      : postponeDateByDate?.date
                  }
                  type={typePostponeDate?.type === "days" ? "text" : "date"}
                  onChange={(e) =>
                    typePostponeDate?.type === "days"
                      ? setPostponeDateByDays({
                        days: stringToNumber(e.target.value),
                      })
                      : setPostponeDateByDate({ date: e.target.value })
                  }
                />
              </Input>
              {typePostponeDate?.type === "days" ? (
                <div className="mt-3">
                  <span>
                    <span>*</span> Cantidad de días, por ejemplo: 2
                  </span>
                </div>
              ) : (
                <div className="mt-3">
                  <span>
                    <span>*</span> Puede ingresar una fecha manual, o puede
                    presionar sobre el calendario para seleccionar una fecha
                  </span>
                </div>
              )}
            </>
          ) : showModal?.key === "chequePayoff" ? (
            <>
              <Card className="d-flex w-100">
                <Card.Content className="m-0">
                  {document.map((data, index) => {
                    return (
                      <div
                        className={`d-flex align-items-center justify-content-between ${index !== document.length - 1 ? "mb-3" : ""
                          }`}
                        key={data?.key}
                      >
                        <Input icon iconPosition="left">
                          <Icon name="tag" />
                          <input
                            placeholder="Número de cheque"
                            onChange={(e) => {
                              const type = "numberCheque";
                              updateDocument(e.target.value, data.key, type);
                            }}
                            value={document.numberCheque}
                          />
                        </Input>
                        <Input icon iconPosition="left">
                          <Icon name="dollar sign" />
                          <input
                            placeholder="Indique el monto"
                            onChange={(e) => {
                              const type = "partialAmount";
                              updateDocument(e.target.value, data.key, type);
                            }}
                            defaultValue={data.partialAmount}
                          />
                        </Input>
                        <Input icon iconPosition="left">
                          <Icon name="calendar" />
                          <input
                            type="date"
                            onChange={(e) => {
                              const type = "fecha";
                              updateDocument(e.target.value, data.key, type);
                            }}
                            defaultValue={data.fecha}
                          />
                        </Input>
                        <Icon
                          link
                          name="minus circle"
                          onClick={() => deleteRow(data.key)}
                        />
                      </div>
                    );
                  })}
                </Card.Content>
              </Card>
              <Button
                basic
                color="black"
                onClick={() => {
                  setCounter(counter + 1);
                  setDocument([
                    ...document,
                    {
                      partialAmount: showModal?.amount,
                      numberCheque: null,
                      fecha: null,
                      key: counter + 1,
                    },
                  ]);
                }}
              >
                + Agregar Cheque
              </Button>
            </>
          ) : showModal?.key === "factorize" ? (
            <>
              <div className="mb-3">
                <span>* </span>
                <span>Monto a factorizar:</span>
              </div>
              <Input icon iconPosition="left">
                <Icon name="dollar sign" />
                <input
                  type="amount"
                  onChange={(e) => setFactorizePayoff(e.target.value)}
                  value={formatMoney(factorizePayoff)}
                />
              </Input>
              {moneyToNumber(factorizePayoff) > showModal?.amount && (
                <div className="mt-3">
                  <span className="text-danger">{`El nuevo monto debe tener un rango entre $ 0 y $ ${formatMoney(
                    showModal?.amount
                  )}`}</span>
                </div>
              )}
            </>
          ) : showModal?.key === "partialPay" ? (
            <>
              <div>
                <span>
                  *{" "}
                </span>
                <span style={{ color: "black" }}>Monto a seleccionar</span>
              </div>
              <span>
                $
                <input
                  type="amount"
                  onChange={(e) => setProviderPartialPayoff(e.target.value)}
                  value={formatMoney(providerPartialPayoff)}
                ></input>
              </span>
              {stringToNumber(providerPartialPayoff) >
                showModal?.data?.remaining ? (
                <span className="text-alert">{`El nuevo monto debe tener un rango entre $ 0 y ${toMoney(
                  showModal?.data?.remaining
                )}`}</span>
              ) : (
                ""
              )}
            </>
          ) : (
            <></>
          )}
        </Modal.Content>
        <Modal.Actions>
          {!showModal.hideCancelButton && (
            <Button onClick={closeModal}>
              {showModal?.cancelButtonIcon && (
                <Icon name={showModal.cancelButtonIcon} />
              )}{" "}
              {showModal?.onCancelText || "Cancelar"}
            </Button>
          )}
          {!showModal.hideAcceptButton && (
            <Button
              color="blue"
              loading={loading}
              onClick={() => {
                if (
                  showModal?.key === "partialPay" ||
                  showModal?.key === "changeAmount"
                ) {
                  providerFunctions();
                } else if (showModal?.key === "agenda") {
                  deletingAgenda();
                } else {
                  setLoading(true);
                  updateObligation();
                }
              }}
            >
              {showModal?.confirmButtonIcon && (
                <Icon name={showModal.confirmButtonIcon} />
              )}{" "}
              {showModal?.onConfirmText || "Aceptar"}{" "}
              {showModal?.confirmButtonIconRight && (
                <Icon name={showModal.confirmButtonIconRight} />
              )}
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
}
