export const Roles = {
    USER : 'USER',
    ADMIN : 'ADMIN',
    NOMINAS : 'NOMINAS',
    WORKER : 'WORKER',
    FLUJO_DE_CAJA : 'FLUJO_DE_CAJA',
    USER_SMARTCFO : 'USER_SMARTCFO',
    USER_PEPPER : 'USER_PEPPER',
    COLABORADOR : 'COLABORADOR',
    FOUNDER: 'FOUNDER',
    SUPER_COLABORADOR: 'SUPER_COLABORADOR'
}