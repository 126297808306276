export const liquidezData = [
  {
    value: "$6.448.242",
    type: "Saldo cuenta corriente",
    label: "CC",
  },
  {
    value: "$8.230.987",
    type: "Línea de crédito",
    label: "LC",
  },
  {
    value: "$42.309",
    type: "Por cobrar",
    alert: "$20.500",
    label: "PC",
    color: "c-green",
    options: true,
  },
  {
    value: "$42.456",
    type: "Por pagar",
    alert: "$20.750",
    label: "PP",
    color: "c-red",
    options: true,
  },
];

export const panelesDashboard = {
  header1: "ENE",
  header2: "2021",
  footer1: "Mes en curso",
  footer2: "Año en curso",
  value1: "$16.448.242",
  value2: "$9.230.987",
};

export const barCreditos = {
  labels: ["Ene 20", "Feb 20", "Mar 20", "Abr 20", "May 20"],
  data: [14, 100, 32, 12, 14],
};

export const creditosTable = {
  header: [
    "Crédito",
    "Tipo",
    "Banco",
    "Emisión",
    "Moneda",
    "Intereses",
    "Capital",
    "Total",
    "Cuadro de pagos",
  ],
  body: [
    [
      "Crédito cov",
      { value: "FC", color: 0 },
      "Santander",
      "11/12/2020",
      "CLP",
      "0",
      "0",
      "$20.465.12",
      "Por Completar",
    ],
    [
      "Crédito MM34",
      { value: "CM", color: 1 },
      "Provincial",
      "11/12/2020",
      "CLP",
      "$20.465.12",
      "$20.465.12",
      "$20.465.12",
      "Por Completar",
    ],
  ],
  total: [
    "Total",
    "",
    "",
    "",
    "",
    "$20.465.12",
    "$20.465.12",
    "$20.465.12",
    "",
  ],
};

export const barCreditosLine = {
  labels: ["Ene 20", "Feb 20", "Mar 20", "Abr 20", "May 20"],
  data: [14, 100, 32, 12, 14],
  dataPair: [4, 80, 22, 5, 8],
};

export const barDashboard = {
  labels: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  data: [
    1200234, 1900324, 3000000, 5000000, 2000000, 2000000, 5050000, 4050000,
    3000000, 5000000, 2000000, 3000500,
  ],
  tooltip: " Venta ",
};

export const barDashboardLine = {
  labels: ["Vigentes", "de 1 a 30 días", "de 31 a 60 días", "de 61 a 90 días"],
  data: [12, 19, 3, 5],
};

export const lineDashboard = {
  labels: ["", "01/12", "07/12", "08/12", "15/12", "22/12", "29/12"],
  datasets: [
    {
      label: "Serie de Ingresos",
      data: [150, 100, 150, 50, 85, 92, 175],
      backgroundColor: "rgba(61, 233, 188, 0.2)",
      borderColor: "green",
      borderWidth: 1,
      lineTension: 0,
    },
    {
      label: "Serie de Egresos",
      data: [30, 60, 30, 90, 115, 130, 80],
      backgroundColor: "rgba(153, 52, 239, 0.2)",
      borderColor: "#9934EF",
      borderWidth: 1,
      lineTension: 0,
    },
    {
      label: "Serie Saldo",
      data: [],
      backgroundColor: "rgba(212, 212, 212, 0.2)",
      borderColor: "#D4D4D6",
      borderWidth: 1,
      lineTension: 0,
    },
  ],
};

export const notificacionesDashboard = [
  { title: "Proximo vto.", date: "15/12/2020" },
  { title: "Proximo pago", date: "15/12/2020" },
  { title: "Cobranza", date: "15/12/2020" },
  { title: "Cobranza", date: "15/12/2020" },
];

export const cobranzaDashboard = {
  header: ["Monto", "Razon Social"],
  data: [
    { title: "Santander Chile", amount: 135068 },
    { title: "Santander Chile", amount: 135068 },
    { title: "Santander Chile", amount: 135068 },
    { title: "Santander Chile", amount: 135068 },
  ],
};

export const cobranzaApi = {
  labels: [
    "Documentos Vigentes",
    "Vencidos de 1 a 15 días",
    "Vencidos de 15 a 30 días",
    "Vencidos de 30 a 60 días",
    "Vencidos de 60 a 90 días",
  ],
  data: [5, 10, 15, 20, 50],
};

export const flujocajaData = {
  semanal: {
    header: [
      { title: "S 1", subtitle: "2021" },
      { title: "S 2", subtitle: "2021" },
      { title: "S 3", subtitle: "2021" },
      { title: "S 4", subtitle: "2021" },
      { title: "S 5", subtitle: "2021" },
      { title: "S 6", subtitle: "2021" },
      { title: "S 7", subtitle: "2021" },
      { title: "S 8", subtitle: "2021" },
      { title: "S 9", subtitle: "2021" },
      { title: "S 10", subtitle: "2021" },
      { title: "S 11", subtitle: "2021" },
      { title: "S 12", subtitle: "2021" },
    ],
    values: [
      {
        values: [
          {
            title: "Clientes a crédito",
            values: [
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
            ],
            total: "$999.999.999",
          },
          {
            title: "Honorarios",
            values: [
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$534.567",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$534.567",
              "$0.00",
              "$0.00",
            ],
            total: "$0.00",
          },
          {
            title: "Gastos RRHH",
            values: [
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
            ],
            total: "$0.00",
          },
          {
            title: "Otros proveedores",
            values: [
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
            ],
            total: "$0.00",
          },
          {
            title: "Proveedores por pagar",
            values: [
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
            ],
            total: "$0.00",
          },
          {
            title: "Cuotas de crédito",
            values: [
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
            ],
            total: "$0.00",
          },
          {
            title: "Declaración F-29 IVA",
            values: [
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
            ],
            total: "$0.00",
          },
          // {
          //   title: "Importaciones e impuestos",
          //   values: [
          //     "$0.00",
          //     "$0.00",
          //     "$0.00",
          //     "$0.00",
          //     "$0.00",
          //     "$0.00",
          //     "$0.00",
          //     "$0.00",
          //     "$0.00",
          //     "$0.00",
          //     "$0.00",
          //     "$0.00",
          //   ],
          //   total: "$0.00",
          // },
          {
            title: "Importaciones",
            values: [
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
            ],
            total: "$0.00",
          },{
            title: "Impuestos",
            values: [
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
            ],
            total: "$0.00",
          },        ],
        total: {
          values: [
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
          ],
          total: "$0.00",
        },
      },
      {
        values: [
          {
            title: "Prueba3",
            values: [
              "$0.00",
              "$1.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
            ],
            total: "$0.00",
          },
          {
            title: "Prueba4",
            values: [
              "$0.00",
              "$3.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
            ],
            total: "$0.00",
          },
        ],
        total: {
          values: [
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
          ],
          total: "$0.00",
        },
      },
    ],
    subtotal: [
      {
        title: "Prueba7",
        values: [
          "$0.00",
          "$1.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
        ],
        total: "$0.00",
      },
      {
        title: "Prueba8",
        values: [
          "$0.00",
          "$3.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
        ],
        total: "$0.00",
      },
    ],
    total: {
      title: "Prueba9",
      values: [
        "$0.00",
        "$1.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
      ],
      total: "$0.00",
    },
  },
  mensual: {
    header: [
      { title: "FEB", subtitle: "2021" },
      { title: "MAR", subtitle: "2021" },
      { title: "ABR", subtitle: "2021" },
      { title: "MAY", subtitle: "2021" },
      { title: "JUN", subtitle: "2021" },
      { title: "JUL", subtitle: "2021" },
      { title: "AGO", subtitle: "2021" },
      { title: "SEP", subtitle: "2021" },
      { title: "OCT", subtitle: "2021" },
      { title: "NOV", subtitle: "2021" },
      { title: "DIC", subtitle: "2021" },
      { title: "ENE", subtitle: "2021" },
    ],
    values: [
      {
        values: [
          {
            title: "Prueba1",
            values: [
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
              "$999.999.999",
            ],
            total: "$999.999.999",
          },
          {
            title: "Prueba2",
            values: [
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
            ],
            total: "$0.00",
          },
        ],
        total: {
          values: [
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
          ],
          total: "$0.00",
        },
      },
      {
        values: [
          {
            title: "Prueba3",
            values: [
              "$0.00",
              "$1.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
            ],
            total: "$0.00",
          },
          {
            title: "Prueba4",
            values: [
              "$0.00",
              "$3.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
              "$0.00",
            ],
            total: "$0.00",
          },
        ],
        total: {
          values: [
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
            "$0.00",
          ],
          total: "$0.00",
        },
      },
    ],
    subtotal: [
      {
        title: "Prueba7",
        values: [
          "$0.00",
          "$1.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
        ],
        total: "$0.00",
      },
      {
        title: "Prueba8",
        values: [
          "$0.00",
          "$3.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
          "$0.00",
        ],
        total: "$0.00",
      },
    ],
    total: {
      title: "Prueba9",
      values: [
        "$0.00",
        "$1.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
        "$0.00",
      ],
      total: "$0.00",
    },
  },
};
export const flujoCajaTablaMensual = {
  header: ["Mes 1", "Mes 2"],
  ingresos: {
    cliente_credito: {
      corriente: "0.00",
      proximo: "0.00",
    },
    proyeccion_venta: {
      corriente: "0.00",
      proximo: "0.00",
    },
    ingresos_totales: {
      corriente: "0.00",
      proximo: "0.00",
    },
  },
  egresos: {
    gastos_rrhh: {
      corriente: "0.00",
      proximo: "0.00",
    },
    arrendos: {
      corriente: "0.00",
      proximo: "0.00",
    },
    proveedores_pagar: {
      corriente: "0.00",
      proximo: "0.00",
    },
    financiamiento: {
      corriente: "0.00",
      proximo: "0.00",
    },
    // impuestos_importaciones: {
    //   corriente: "0.00",
    //   proximo: "0.00",
    // },
    impuestos: {
      corriente: "0.00",
      proximo: "0.00",
    },
    importaciones: {
      corriente: "0.00",
      proximo: "0.00",
    },    
    egresos_totales: {
      corriente: "0.00",
      proximo: "0.00",
    },
  },
  resumen: {
    variacion_caja: {
      corriente: "0.00",
      proximo: "0.00",
    },
    saldo_inicial: {
      corriente: "0.00",
      proximo: "0.00",
    },
    saldo_final: {
      corriente: "0.00",
      proximo: "0.00",
    },
  },
};

export const flujoCajaTablaSemanal = {
  header: ["Semana 1", "Semana 2"],
  ingresos: {
    cliente_credito: {
      corriente: "0.00",
      proximo: "0.00",
    },
    proyeccion_venta: {
      corriente: "0.00",
      proximo: "0.00",
    },
    ingresos_totales: {
      corriente: "0.00",
      proximo: "0.00",
    },
  },
  egresos: {
    gastos_rrhh: {
      corriente: "0.00",
      proximo: "0.00",
    },
    arrendos: {
      corriente: "0.00",
      proximo: "0.00",
    },
    proveedores_pagar: {
      corriente: "0.00",
      proximo: "0.00",
    },
    financiamiento: {
      corriente: "0.00",
      proximo: "0.00",
    },
    // impuestos_importaciones: {
    //   corriente: "0.00",
    //   proximo: "0.00",
    // },
    impuestos: {
      corriente: "0.00",
      proximo: "0.00",
    },
    importaciones: {
      corriente: "0.00",
      proximo: "0.00",
    },
    egresos_totales: {
      corriente: "0.00",
      proximo: "0.00",
    },
  },
  resumen: {
    variacion_caja: {
      corriente: "0.00",
      proximo: "0.00",
    },
    saldo_inicial: {
      corriente: "0.00",
      proximo: "0.00",
    },
    saldo_final: {
      corriente: "0.00",
      proximo: "0.00",
    },
  },
};

export const financiamientoCard = {
  linea_credito: {
    disponible: "1.500.000",
    utilizados: "1.500.000",
    autorizados: "1.500.000",
  },
  fondos_mutuos: {
    monto: "1.500.000",
    rentabilidad: "1.500.000",
  },
  depositos: {
    monto: "1.500.000",
  },
  tarjetas_credito: {
    monto: "1.500.000",
  },
  updateDate: "Ultima actualizacion 10 ene 2021 00:21",
};

export const dataProveedores = [
  {
    title: "Sasco spa",
    rut: "RUT: 78 345 768-5",
    saldo: "$17.680",
    selec: "$17.680",
    docs: "08/08",
    msg: "",
    header: [
      "N Doc",
      "Tipo",
      "Emisión",
      "Vencimiento",
      "Vence en",
      "Monto",
      "Pagar",
      "",
    ],
    rows: [
      {
        doc: "0908445",
        tipo: "Factura de venta",
        emision: "26/05/2020",
        vencimiento: "26/05/2020",
        vence: "26/05/2020",
        monto: "$ 17.680",
      },
      {
        doc: "1234564",
        tipo: "Factura exenta",
        emision: "28/06/2020",
        vencimiento: "26/05/2020",
        vence: "26/05/2020",
        monto: "$ 17.680",
      },
      {
        doc: "0908455",
        tipo: "Factura electrónica",
        emision: "28/06/2020",
        vencimiento: "26/05/2020",
        vence: "26/05/2020",
        monto: "$ 17.680",
      },
    ],
  },
  {
    title: "Tores Garden",
    rut: "RUT: 78 345 768-5",
    saldo: "$20.690",
    selec: "$17.680",
    docs: "08/08",
    msg: "Faltan datos del proveedor",
    header: [
      "N Doc",
      "Tipo",
      "Emisión",
      "Vencimiento",
      "Vence en",
      "Monto",
      "Pagar",
      "",
    ],
    rows: [
      {
        doc: "0908445",
        tipo: "Factura de venta",
        emision: "26/05/2020",
        vencimiento: "26/05/2020",
        vence: "26/05/2020",
        monto: "$ 17.680",
      },
      {
        doc: "0908455",
        tipo: "Factura exenta",
        emision: "28/06/2020",
        vencimiento: "26/05/2020",
        vence: "26/05/2020",
        monto: "$ 17.680",
      },
      {
        doc: "0128445",
        tipo: "Factura electrónica",
        emision: "28/06/2020",
        vencimiento: "26/05/2020",
        vence: "26/05/2020",
        monto: "$ 17.680",
      },
    ],
  },
];

export const dataConexiones = {
  claves: [
    {
      id: 1,
      rut: "",
      action: 0,
      status: null,
      lastUpdate: "Úlima actualización 10 ene 2021 00:21",
      name: "Santander",
    },
    {
      id: 2,
      rut: "",
      action: 0,
      status: null,
      lastUpdate: "Úlima actualización 10 ene 2021 00:21",
      name: "Santander",
    },
  ],
  integraciones: [
    {
      id: 1,
      status: 0,
      rut: "",
      lastUpdate: "Úlima actualización 10 ene 2021 00:21",
      name: "Santander",
      action: 1,
    },
    {
      id: 2,
      status: 0,
      rut: "",
      lastUpdate: "Úlima actualización 10 ene 2021 00:21",
      name: "Santander",
      action: 1,
    },
  ],
  conexionesBancarias: [
    {
      id: 1,
      status: 0,
      lastUpdate: "Úlima actualización 10 ene 2021 00:21",
      name: "Santander",
      rut: "78 345 768-5",
      action: 1,
    },
  ],
  dataInsertBancaria: {
    id: 90,
    status: 0,
    rut: "11 222 333-4",
    lastUpdate: "Úlima actualización 10 ene 2021 00:21",
    name: "Santander",
    action: 1,
  },
};

export const forms = {
  conexiones: {
    title: "Nueva contraseña",
    forms: [
      { type: "subtitle", name: "Datos del contacto", showFilters: false },
      {
        type: "input",
        label: "Nombre*",
        id: "nombre",
        placeholder: "Entrada única",
      },
      {
        type: "input",
        label: "Tipo*",
        id: "tipo",
        placeholder: "Entrada única",
      },
      {
        type: "multiselect",
        label: "Roles*",
        id: "multiselect",
        options: [{ name: "admin", id: 1 }],
      },
    ],
  },
  flujocaja: {
    title: "Agregar fuente de datos",
    forms: [
      {
        type: "subtitleTop",
        name: "Nueva fuente de datos",
        showFilters: false,
      },
      {
        type: "name",
        label: "Nombre",
        id: "nombre",
        placeholder: "Nombre",
      },
      {
        type: "select",
        label: "Tipo",
        id: "tipo",
        options: [
          { option: "Seleccionar", values: "" },
          { option: "Entrada única", values: "singleEntry" },
          { option: "Entrada recurrente", values: "recurrent" },
          { option: "Saldo inicial", values: "initialBalance" },
          { option: "Créditos", values: "credits" },
        ],
        values: ["", "singleEntry", "recurrent", "initialBalance", "credits"],
      },
      {
        type: "textarea",
        label: "Comentario",
        id: "comentario",
        placeholder: "Comentario",
      },
      { type: "subtitle", name: "Detalle", showFilters: false },
      {
        type: "creditType",
        label: "Tipo de crédito",
        id: "creditType",
        options: [
          { option: "Comercial", value: "comercial" },
          { option: "Bullet", value: "bullet" },
          { option: "Comex", value: "comex" },
          { option: "Carta de Crédito", value: "cartaCredito" },
          { option: "Leasing", value: "leasing"},
        ],
      },
      {
        type: "banks",
        label: "Banco",
        id: "banks",
        options: [
          {
            value: 'bankBBVA',
            name: 'Banco BBVA',
          },
          {
            value: 'bci',
            name: 'Banco BCI',
          },
          {
            value: 'bankBICE',
            name: 'Banco BICE',
          },
          {
            value: 'bankChile',
            name: 'Banco Chile',
          },
          {
            value: 'bankChile2',
            name: 'Banco de Chile',
          },
          {
            value: 'bankEstado',
            name: 'Banco Estado',
          },
          {
            value: 'bankItau',
            name: 'Banco Itau',
          },
          {
            value: 'santander',
            name: 'Banco Santander',
          },
          {
            value: 'security',
            name: 'Banco Security',
          },
          {
            value: 'falabella',
            name: 'Banco Falabella',
          },
          {
            value: 'scotiabank',
            name: 'Banco Scotiabank',
          },
          {
            value: 'internacional',
            name: 'Banco Internacional',
          },
          {
            value: 'ripley',
            name: 'Banco Ripley',
          },
          {
            value: 'corpbanca',
            name: 'Banco CORPBANCA',
          },
          {
            value: 'consorcio',
            name: 'Banco Consorcio',
          },
          {
            value: 'coopeuch',
            name: 'Banco Coopeuch',
          },
        ],
      },
      {
        type: "identificator",
        label: "Identificador",
        placeholder: "Identificador del crédito",
        id: "identificator",
      },
      {
        type: "area",
        label: "Área",
        id: "area",
        placeholder: "Área",
      },
      {
        type: "repeat",
        label: "Repetir",
        id: "repeat",
        options: [
          { option: "Seleccione repetición", values: "" },
          { option: "Cada 7 días", values: 7 },
          { option: "Cada 15 días", values: 15 },
          { option: "Cada 30 días", values: 30 },
          { option: "Cada 45 días", values: 45 },
          { option: "Cada 60 días", values: 60 },
          { option: "Cada 90 días", values: 90 },
        ],
      },
      {
        type: "pay",
        label: "Pago",
        id: "pay",
        options: [
          { option: "Por Fecha", values: "2" },
          { option: "Primer día hábil", values: "1" },
          { option: "Quinto día hábil", values: "5" },
          { option: "Último día hábil", values: "30" },
        ],
      },
      {
        type: "amount",
        label: "Monto ($)",
        id: "monto",
        placeholder: "Monto",
      },
      {
        type: "paymentsAmount",
        label: "Monto cuota",
        id: "paymentsAmount",
        placeholder: "Monto de la cuota",
      },
      {
        type: "payments",
        label: "Cuotas",
        id: "payments",
        placeholder: "Número de cuotas",
      },
      {
        type: "fees",
        label: "Tasa",
        id: "fees",
        placeholder: "0.5%",
      },
      {
        type: "feesPeriod",
        label: "Período Tasa",
        id: "feesPeriod",
        options: [
          { value: "mensual", name: "Mensual" },
          { value: "anual", name: "Anual" },
        ],
      },
      {
        type: "date",
        label: "Fecha",
        id: "date",
      },
      {
        type: "finalDate",
        label: "Hasta",
        id: "finalDate",
      },
      {
        type: "currency",
        label: "Moneda",
        id: "moneda",
        options: [{ option: "CLP", values: "CLP" }],
      },
    ],
  },
  proveedores: {
    title: "Re-incluir Proveedor",
    forms: [
      { type: "subtitle", name: "Resumen", showFilters: false },
      {
        type: "input",
        label: "RUT*",
        id: "rut",
        placeholder: "Entrada única",
      },
      {
        type: "input",
        label: "Nombre*",
        id: "nombre",
        placeholder: "Entrada única",
      },
      {
        type: "multiple",
        values: [
          {
            type: "input",
            label: "Total*",
            id: "total1",
            placeholder: "Entrada única",
          },
          {
            type: "input",
            label: "Total*",
            id: "total2",
            placeholder: "Entrada única",
          },
        ],
      },
      {
        type: "input",
        label: "Total",
        id: "total",
        placeholder: "Entrada única",
      },
      {
        type: "select",
        label: "Pagar con banco",
        id: "pagarconbanco",
        options: ["Seleccionar"],
      },
      { type: "button", label: "Informar" },
      { type: "subtitle", name: "Pagos adicionales", showFilters: false },
      {
        type: "input",
        label: "RUT*",
        id: "rutpagosadicionales",
        placeholder: "Entrada única",
      },
      {
        type: "input",
        label: "Nombre*",
        id: "nombrepagosadicionales",
        placeholder: "Entrada única",
      },
      {
        type: "multiple",
        values: [
          {
            type: "input",
            label: "Tipo de documento",
            id: "tipodedocumento",
            placeholder: "Entrada única",
          },
          {
            type: "input",
            label: "Detalle",
            id: "detalle",
            placeholder: "Entrada única",
          },
        ],
      },
      {
        type: "multiple",
        values: [
          {
            type: "input",
            label: "Moneda",
            id: "moneda",
            placeholder: "Entrada única",
          },
          {
            type: "input",
            label: "A pagar",
            id: "apagar",
            placeholder: "Entrada única",
          },
        ],
      },
    ],
  },
  documentostributarios: {
    title: "Filtrar",
    forms: [
      { type: "subtitle", name: "Proveedor", showFilters: true },
      { type: "input", label: "RUT*", id: "rut", placeholder: "Entrada única" },
      { type: "subtitle", name: "Documentos", showFilters: false },
      {
        type: "input",
        label: "Folio",
        id: "folio",
        placeholder: "Entrada única",
      },
      {
        type: "multiple",
        values: [
          {
            type: "input",
            label: "Monto Mínimo",
            id: "montominimo",
            placeholder: "Entrada única",
          },
          {
            type: "input",
            label: "Monto Máximo",
            id: "montomaximo",
            placeholder: "Entrada única",
          },
        ],
      },
      { type: "subtitle", name: "Fechas", showFilters: false },
      {
        type: "multiple",
        values: [
          {
            type: "input",
            label: "Fecha inicial",
            id: "fechainicial",
            placeholder: "Entrada única",
          },
          {
            type: "input",
            label: "Fecha final",
            id: "fechafinal",
            placeholder: "Entrada única",
          },
        ],
      },
      { type: "subtitle", name: "Estado", showFilters: false },
      {
        type: "select",
        label: "Estado",
        id: "estado",
        options: ["Seleccionar"],
      },
    ],
  },
  agenda: {
    title: "Nuevo cliente",
    forms: [
      { type: "subtitle", name: "Datos del contacto" },
      { type: "input", label: "RUT*", id: "rut", placeholder: "Entrada única" },
      {
        type: "input",
        label: "Nombre",
        id: "nombre",
        placeholder: "Entrada única",
      },
      {
        type: "input",
        label: "Email",
        id: "email",
        placeholder: "Entrada única",
      },
      { type: "select", label: "Tipo", id: "tipo", options: ["Seleccionar"] },
      {
        type: "select",
        label: "Área de flujo de caja",
        id: "area",
        options: ["Seleccionar"],
      },
      { type: "subtitle", name: "Datos tributarios" },
      {
        type: "input",
        label: "Dirección",
        id: "direccion",
        placeholder: "Entrada única",
      },
      {
        type: "input",
        label: "Días de pago",
        id: "diasdepago",
        placeholder: "Entrada única",
      },
      {
        type: "input",
        label: "Giro comercial",
        id: "girocomercial",
        placeholder: "Entrada única",
      },
      { type: "subtitle", name: "Datos tributarios" },
      { type: "select", label: "Banco", id: "banco", options: ["Seleccionar"] },
      {
        type: "input",
        label: "Tipo de cuenta",
        id: "tipocuenta",
        placeholder: "Entrada única",
      },
    ],
  },
  creditos: {
    title: "Nuevo Crédito",
    forms: [
      {
        type: "subtitle",
        name: "Datos",
      },
      {
        type: "select",
        label: "Tipo de Crédito",
        id: "tipo",
        options: ["Seleccionar"],
      },
      {
        type: "select",
        label: "Banco",
        id: "banco",
        options: ["Seleccionar"],
      },
      {
        type: "input",
        label: "Identificador*",
        id: "identificador",
        placeholder: "Entrada única",
      },
      {
        type: "multiple",
        values: [
          {
            type: "input",
            label: "Monto Liquido*",
            id: "montoliquido",
            placeholder: "Entrada única",
          },
          {
            type: "select",
            label: "Moneda",
            id: "moneda",
            options: ["Seleccionar"],
          },
        ],
      },
      {
        type: "multiple",
        values: [
          {
            type: "input",
            label: "Monto Cuota*",
            id: "montocuota",
            placeholder: "Entrada única",
          },
          {
            type: "input",
            label: "Cuotas",
            id: "cuotas",
            placeholder: "Entrada única",
          },
        ],
      },
      {
        type: "multiple",
        values: [
          {
            type: "input",
            label: "Tasa (%)",
            id: "tasa",
            placeholder: "Entrada única",
          },
          {
            type: "select",
            label: "Periódo Tasa",
            id: "periodo",
            options: ["Seleccionar"],
          },
        ],
      },
      {
        type: "multiple",
        values: [
          {
            type: "input",
            label: "Fecha Emisión",
            id: "fechaemision",
            placeholder: "Entrada única",
          },
          {
            type: "input",
            label: "Primer Vencimiento*",
            id: "primervencimiento",
            placeholder: "Entrada única",
          },
        ],
      },
    ],
  },
};

export const monedas = {
  dolar: "USD",
  uf: "UF",
};

export const panelAgendaData = [
  // tipo 1 CLIENTE, tipo 2 PROVEEDOR, tipo 3 CLIENTE PROVEEDOR
  {
    razonSocial: "Data Space",
    cliente: "Cliente",
    rut: "20.000.000",
    email: "ejemplo@dataspace.com",
    tipo: 1,
  },
  {
    razonSocial: "Data Random",
    cliente: "Proveedor",
    rut: "20.000.000",
    email: "ejemplo@dataspace.com",
    tipo: 2,
  },
  {
    razonSocial: "Data Random",
    cliente: "Cliente/Proveedor",
    rut: "20.000.000",
    email: "ejemplo@dataspace.com",
    tipo: 3,
  },
  {
    razonSocial: "Data Space",
    cliente: "Cliente",
    rut: "20.000.000",
    email: "ejemplo@dataspace.com",
    tipo: 1,
  },
];

export const creditosVigentes = [
  { cliente: "Santander", tipo: "Credito Comercial", monto: "1.500.000" },
  { cliente: "Santander", tipo: "Credito Comercial", monto: "1.500.000" },
  { cliente: "Santander", tipo: "Credito Comercial", monto: "1.500.000" },
  { cliente: "Santander", tipo: "Credito Comercial", monto: "1.500.000" },
  { cliente: "Santander", tipo: "Credito Comercial", monto: "1.500.000" },
];

export const creditosResumen = [
  {
    cliente: "Santander",
    total: "17.500.000",
    insoluto: "17.500.000",
    cuota: "70.500",
    moneda: "CLP",
    tasa: "0.00",
    cuotasPagas: 0,
    cuotasTotales: 72,
    primerPago: "02/06/2021",
  },
];

export const docTributariosData = [
  {
    rut: 17500000,
    razonSocial: "Data Sapoe",
    tipo: "FC",
    folio: 116,
    montoTotal: 130000,
    saldo: 0,
    formaPago: "Contado",
    fechaPago: "12/12/2021",
    fechaEmision: "10/12/2021",
    categoria: "aprov.",
    estado: true,
  },
  {
    rut: 17500000,
    razonSocial: "Santander",
    tipo: "FC",
    folio: 116,
    montoTotal: 130000,
    saldo: 0,
    formaPago: "Contado",
    fechaPago: "12/12/2021",
    fechaEmision: "10/12/2021",
    categoria: "aprov.",
    estado: true,
  },
  {
    rut: 17500000,
    razonSocial: "CITI",
    tipo: "FC",
    folio: 116,
    montoTotal: 130000,
    saldo: 0,
    formaPago: "Contado",
    fechaPago: "12/12/2021",
    fechaEmision: "10/12/2021",
    categoria: "aprov.",
    estado: false,
  },
];

export const generalTableData = [
  {
    rut: "55555555-5",
    nombre: "Banco Santander",
  },
  {
    rut: "55555555-5",
    nombre: "Banco Santander",
  },
  {
    rut: "55555555-5",
    nombre: "Banco Santander",
  },
  {
    rut: "55555555-5",
    nombre: "Banco Santander",
  },
  {
    rut: "55555555-5",
    nombre: "Banco Santander",
  },
];
