import { useSubscription, useQuery } from "urql";
import { banks } from "../utils/bankList";
import { botList, botIndex } from "../utils/botList";
import moment from "moment";

const BOT_LOGS = `subscription BOT_LOGS($companyId: String!) {
    botlogResponse(companyId: $companyId) {
      empresa
      botname
      status
      mssg
    }
  }`;

export const SubcribeWS = (companyId) => {
  const handleSubscription = (messages = [], response) => {
    if (response) {
      const { botlogResponse } = response;
      const format = {
        title: botIndex[botlogResponse.botname] || botlogResponse?.botname,
        description:
          botlogResponse.mssg == "Ejecutado de forma correcta"
            ? "Ejecutado de forma correcta"
            : botlogResponse?.botname === "F29/Status" ||
              botlogResponse?.botname === "PREVIRED/imposiciones" ||
              botlogResponse?.botname === "sii" || 
              botlogResponse?.botname === "bsaleApiToken"
            ? botlogResponse?.mssg
            : "Ejecucion con errores",
        ejecucionCorrecta:
          botlogResponse.mssg === "Ejecutado de forma correcta"
            ? true
            : botlogResponse?.botname === "F29/Status" ||
              botlogResponse?.botname === "PREVIRED/imposiciones" ||
              botlogResponse?.botname === "sii" || 
              botlogResponse?.botname === "bsaleApiToken"
            ? botlogResponse?.status
            : false,
        ago: moment().format("DD-MM-YYYY HH:mm:ss"),
        visited: false,
        botName: botlogResponse?.botname,
        mssg: botlogResponse.mssg,
        ok:
        botlogResponse.mssg === "Ejecutado de forma correcta"
            ? true
            : botlogResponse?.botname === "F29/Status" ||
              botlogResponse?.botname === "PREVIRED/imposiciones" ||
              botlogResponse?.botname === "sii" ||
              botlogResponse?.botname === "bsaleApiToken"
            ? botlogResponse?.status
            : false,
      };
  
      return [format];
    }

    return [{}]
  };

  const [res] = useSubscription(
    {
      query: BOT_LOGS,
      variables: { companyId },
    },
    handleSubscription
  )

  return res
};

const LAST_BOT_DATE = `query UpdateDocsDate($botname: String!, $empresa: String!) {
  UpdateDocsDate(botname: $botname, empresa: $empresa) {
    id
    status
    empresa
    botname
    botend
  }
}`;

export const BotUpdateDate = (botname, empresa) => {
  const [result, reexecuteQuery] = useQuery({
    query: LAST_BOT_DATE,
    variables: { botname, empresa },
  });
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteQuery };
}

const FIND_LAST_BOTLOG = `query FindLastBotlog($passwordId: String!){
  FindLastBotlog(passwordId: $passwordId){
    id
    botend
    status
    mssg
  }
}`;

export const FindLastBotlog = (passwordId) => {
  const [result, reexecuteQuery] = useQuery({
    query: FIND_LAST_BOTLOG,
    variables: {passwordId}
  });
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteQuery };
}