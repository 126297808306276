import { types } from "../types/types";

const initialState = {
  checking: true,
  nominasLabelFilter: [],
  cashflowCollapsedCategories: [],
  firstTimeOnApp: false,
  showWelcomeModal: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.authLogin:
      return {
        ...state,
        ...action.payload,
        checking: false,
      };
    case types.authLogout:
      return {
        checking: false,
      };
    case types.updateNominasFilter:
      return {
        ...state,
        nominasLabelFilter: action.payload
      }
    case types.updateCashflowCollapsedCategories:
      return {
        ...state,
        cashflowCollapsedCategories: action.payload,
      };
    case types.authFirstTime:
      return {
        ...state,
        firstTimeOnApp: action.payload
      };
    case types.authShowWelcomeModal:
      return {
        ...state,
        showWelcomeModal: action.payload
      };
    default:
      return state;
  }
};
