import { types } from "../types/types";

const initialState = {
  notification: [],
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getNotifications:
      return {
        ...state,
        notification: [...state.notification, ...action.payload],
      };
    case types.setNotifications:
      return {
        ...state,
        notification: [...action.payload],
      };
    case types.authLogout:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
