import { types } from "../types/types";

export const getAlert = (values) => ({
  type: types.getAlertState,
  payload: values,
});

export const importAlertState = (state) => {
  return async (dispatch) => {
    try {
      dispatch(getAlert(state));
    } catch (e) {
      console.error(e);
    }
  };
};
