import { types } from "../types/types";

export const showModal = (show) => ({
  type: types.getModal,
  payload: show,
});

export const importModal = (show) => {
  return async (dispatch) => {
    try {
      dispatch(showModal(show));
    } catch (error) {
      console.error(error);
    }
  };
};
