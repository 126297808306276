/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch } from "react-redux";
import { importAlertState } from "../../redux/actions/alert";
import moment from 'moment'
import "./style.scss";

const DEFAULT_CLOSE_TIME = 4000

export default function Alert({ alertState }) {
  const dispatch = useDispatch()


  const time = alertState?.duration > 0 ? alertState?.duration * 1000 : DEFAULT_CLOSE_TIME

  const message = <div className="toastMessage mr-2">
        <div className="toastUpper">
          {false && <img src="SANTANDER.svg" />}
          <span className="toastConceptText font-weight-bold">{alertState.concept ? alertState.concept : alertState.state === 'loading' ? "Espere un momento" : alertState.state === "error" ? "Error" : "Éxito!"}</span>
          <span className="toastTime">{moment(alertState.time).format('DD/MM/YYYY HH:MM:SS')}</span>
        </div>
        <div className="toastLower">{alertState.content}</div>
      </div>

  const loadingToast = () => {
    const loadingToast = toast.loading(message, {
      position: "top-right",
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })

    setTimeout(() => toast.update(loadingToast, { render: message, type: alertState.state, isLoading: false, autoClose: DEFAULT_CLOSE_TIME, hideProgressBar: true }), DEFAULT_CLOSE_TIME)
  }

  const otherStateToast = () => {
    toast(message, {
      type: alertState.state,
      position: "top-right",
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      autoClose: DEFAULT_CLOSE_TIME
    })

    setTimeout(() => dispatch(importAlertState()), DEFAULT_CLOSE_TIME)
  }

  useEffect(() => {
    if(!alertState.state) {
      return
    }

  if (alertState?.duration == 0) return 
    if (alertState.state === 'loading') {
      loadingToast()
      dispatch(
        importAlertState({
          content: "",
          state: "",
          duration: 0,
        })
      )
    } else {
      otherStateToast()
      dispatch(
        importAlertState({
          content: "",
          state: "",
          duration: 0,
        })
      )
    }
  }, [alertState])

  return (
    <ToastContainer newestOnTop  style={{zIndex: 100000}} />
  )
}
