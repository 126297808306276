import { currencyOptionsWithSpecials } from "./currencies";
import { CurrencyIcon } from "./currencyIcon";

export function toMoney(amount) {
  return `$ ${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
export function toMoneyFinance(amount) {
  if (amount >= 0) return `$ ${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  else return `$ (${amount * -1})`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
export function chartToMoney(amount) {
  return (
    "$ " +
    Math.trunc(amount * 1000000)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  );
}

export const stringToNumber = (value) => {
  if (value === 0) return 0;
  if (!value) return ""
  return value.toString().replace(/[^0-9,]/g, "").replace(/\./g, "");
};

export const stringDecimalToNumber = value => {
  if (value === 0) return 0;
  if (!value) return 0

  String(value).replace(/[^0-9,]/g, '')

  const cleanValue = value.toString()
    .replace(/[^0-9,\.]/g, "")
    .replace(/,/g, '.')

  return parseFloat(cleanValue)
}

export const currency = (value) => {
  if (value == 0) return 0;
  if (!value) return "";
  const number = String(value).replace(/[^0-9,-]/g, "").replace(/\./g, "");
  return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};


export const parseUsd = (value) => {
  //Cambiamos las comas por un simbolo temporal , luego reemplazamos los , de miles por punto y el simbolo temporal por una coma para el formato 
  const stringWithTempChar = value.replace(/,/g, '#');
  const stringWithComma = stringWithTempChar.replace(/\./g, ',');
  const stringWithInvertedCommaAndDot = stringWithComma.replace(/#/g, '.');
  return stringWithInvertedCommaAndDot;

}

export const formatMoney = (value, currencyType = "UF", toTable = false) => {
  if (value === 0) return "0";
  if (!value) return "";

  if (currencyType == 'USD') {
    if (!toTable) value = value.toString().replace(/\./g, "")


    // Formatear la respuesta como moneda en USD
    const formattedResponse = value.toLocaleString('en-ES', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2, // Mínimo 2 decimales para los centavos
      maximumFractionDigits: 2, // Máximo 2 decimales para los centavos

    });

    // Eliminar el símbolo "$" de la cadena
    const formattedResponseWithoutSymbol = formattedResponse.replace('$', '');

    if (toTable) { return parseUsd(formattedResponseWithoutSymbol) }
    return formattedResponseWithoutSymbol

  }
  const stringAmount = value.toString();
  const amount = stringAmount.replace(/[^\.,0-9-]/g, "")
  const commaPosition = amount.indexOf(",")
  if (commaPosition < 0) return currency(amount)
  const intMoney = amount.split(",")[0]
  const decMoney = amount.split(",")[1]
  if (currencyType == "CLP") return currency(intMoney)
  return currency(intMoney + "," + String(decMoney).substring(0, 2))
};

export function formatNewMoney(amount, currencyType = 'CLP') {
  if ([undefined, null, "",].includes(String(amount).trim())) return ''
  let [int, dec] = String(amount).replace(/[^0-9-.]|-(?=[^-]*-)|\.(?=[^.]*\.)/g, '').split('.') // removes all non-numeric characters except for the minus sign and the dot
  const absInt = int.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace('-', '') || '0'; // in case of null or undefined, returns 0

  if (dec) {
    dec = ',' + dec.slice(0, 2)
  } else {
    dec = '';
  }

  return `${int.includes('-') ? '-' : ''}${absInt + dec}`;
}

export function formatWithDots(amount) {
  const str = String(amount).replace(/[^0-9]/g, '');
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function cleanNumber(amount) {
  let result = String(amount).replace(/[^0-9-,.]|(?!^)-|\.(?=[^.]*\.)/g, '');
  result = result.replace(',', '.').replace(/\.(?=[^.]*\.)/g, '').replace('$', '');

  return Number(result)
}

export const moneyToNumber = (money) => {
  if (!money) return 0;
  if (typeof money == "number") return money;
  return parseFloat(money.replace(/[^0-9,]/g, "").replace(/,/, "."));
};

export const cleanRut = (input) => {
  if (!input) return "";
  return String(input).replace(/[^0-9Kk]/g, "");
};

export const formatRut = (rut) => {
  if (!rut) return "";
  const actual = rut.replace(/^0+/, "");
  if (actual != "" && actual.length > 1) {
    const actualClean = actual.replace(/[^0-9Kk]/g, "");
    const start = actualClean.substring(0, actualClean.length - 1);
    let rutDots = "";
    var j = 1;
    for (let i = start.length - 1; i >= 0; i--) {
      const letter = start.charAt(i);
      rutDots = `${letter}${rutDots}`;
      if (j % 3 == 0 && j <= start.length - 1) {
        rutDots = `.${rutDots}`;
      }
      j++;
    }
    const dv = actualClean.substring(actualClean.length - 1);
    rutDots = `${rutDots}-${dv}`;
    return rutDots.toUpperCase();
  }
  return rut;
};

export const formatVat = (vat) => {
  if (!vat) return "";
  const parsedVat = vat.toUpperCase()
  const actual = parsedVat.replace(/[^A-Z0-9]/g, "")
  return actual;
};

export const rutWithNumbers = (rut) => {
  if (!rut) return "";
  return rut.replace(/[^0-9Kk]/g, "");
};

export const rutWithDash = (rut) => {
  if (!rut) return "";
  //if (rut.includes("-")) return rut.toString().replace(/[^0-9Kk-]/g, "");
  const cleanRut = rutWithNumbers(rut.toString());

  const length = cleanRut.length;
  const base = cleanRut.substring(0, length - 1);
  const dv = cleanRut.substring(length - 1);

  return `${base}-${dv}`;
};

export const finalScroll = (value) => {
  if (
    value?.scrollHeight <=
    Math.round(value?.scrollTop + value?.offsetHeight) + 1
  ) {
    return true;
  }
  return false;
};

export const startScroll = (value) => {
  if (value?.scrollTop <= 5) {
    return true;
  }
  return false;
};

export const numbersOnly = (input) => {
  if (!input) return "";
  return input.toString().replace(/[^0-9]/g, "");
};

export const porcentageFormat = (input) => {
  if (!input) return "";
  return input.toString().replace(/[^0-9,]/g, "");
};

const Fn = {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  validaRut: function (rutCompleto) {
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
    var tmp = rutCompleto.split("-");
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == "K") digv = "k";
    return Fn.dv(rut) == digv;
  },
  dv: function (T) {
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : "k";
  },
};

export const isRutValid = (rutCompleto) => {
  return Fn.validaRut(rutCompleto);
};

export const removeSpaces = (originalString) => {
  if (!originalString) return originalString

  return originalString.replace(/[\s]/g, '')
}

export const removeAccentMark = (originalString) => {
  if (!originalString) return originalString

  return originalString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isEmailValid = (email) => {
  const emailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const isEmail = emailValid.test(email)
  return isEmail
}

export const formatMoneyWithCurrency = (amount, currency = 'CLP') => {
  const invalidAmountInputs = [undefined, null, "",];

  if (invalidAmountInputs.includes(amount)) return "";

  const currencyOptions = currencyOptionsWithSpecials.find((option) => option.key === currency);

  if (!currencyOptions) return "";

  const [integers, decimals] = amount.toString().split('.');

  let formattedAmount = `${currencyOptions.prefix}${integers.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${decimals ? `,${decimals}` : ''}`; //* Default with dot separation
  if (currencyOptions.separation === ',') {
    formattedAmount = `${currencyOptions.prefix}${integers.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${decimals ? `.${decimals}` : ''}`;
    return formattedAmount;
  }

  if (currencyOptions.separation === '.') {
    formattedAmount = `${currencyOptions.prefix}${integers.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${decimals ? `,${decimals}` : ''}`;
    return formattedAmount;
  }

  return formattedAmount;
};
