import { types } from '../types/types'

const initialState = {
    lastUpdateDateSiiBot: null
}

export const botLastUpdateDateReducer = ( state = initialState, action ) => {
    switch(action.type) {
        case types.lastBotUpdateDateSii:
            return {
                ...state,
                lastUpdateDateSiiBot: action.payload
            }
        default:
            return state
    }
}